import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLabEquipmentRequest } from '~/store/modules/lab/actions';
import CreateEquipment from './CreateEquipment';
import MediaCard from './MediaCard';
import Tabs from './Tabs';

// const SHEET_ID = '1gOgydlbsLd3GGOy9xsX4y7kyNyV2dkgDcFhMp_TkpOM';

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0,
  },
  button: {
    flexGrow: 1,
  },
  title: {
    textAlign: 'center',
  },
  dialogPaper: {
    height: '100%',
  },
  content: {
    padding: 0,
    display: 'flex',
  },
  new: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.light,
    width: theme.spacing(10),
  },
  box: {
    padding: theme.spacing(0, 0, 3, 0),
  },
}));

export default function Equipment({ labId }) {
  const classes = useStyles();
  // const [equipment, setEquipment] = useState(null);
  const [equipmentId, setEquipmentId] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [createEquipmentOpen, setCreateEquipmentOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('body');
  const [bookingPage, setBookingPage] = React.useState('');
  const [equipmentName, setEquipmentName] = React.useState('');
  const [detailedDescription, setDetailedDescription] = React.useState('');
  const [procedureDescription, setProcedureDescription] = React.useState('');
  const [trainingDescription, setTrainingDescription] = React.useState('');
  const token = useSelector(state => state.auth.token);
  const profile = useSelector(state => state.user.profile);
  const equipmentList = useSelector(state => state.lab.equipment);
  const dispatch = useDispatch();

  const THIS_LAB_ID = labId;

  useEffect(() => {
    dispatch(getLabEquipmentRequest({ lab_id: labId }));
  }, [dispatch, labId]);

  // const fetchDataAsync = useCallback(async () => {
  //   const result = await drive({
  //     sheet: SHEET_ID,
  //     tab: '4',
  //     cache: 0,
  //   });
  //   /** FILTRA DADOS COM BASE NO ID DO LABORATÓRIO ----- TEMPORÁRIO */
  //   const filteredTable = result.filter(
  //     row => parseInt(row.labid, 10) === THIS_LAB_ID
  //   );
  //   setEquipment(filteredTable);
  // }, [THIS_LAB_ID]);

  // useEffect(() => {
  //   fetchDataAsync();
  // }, [fetchDataAsync]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleEquipmentOpen = scrollType => () => {
    if (token !== null) {
      setCreateEquipmentOpen(true);
      setScroll(scrollType);
    }
  };

  const handleEquipmentClose = () => {
    setCreateEquipmentOpen(false);
  };

  const handleClickOpen = (
    scrollType,
    iFrameSource,
    iFrameTitle,
    isActive,
    description,
    procedure,
    training,
    id
  ) => () => {
    if (isActive === 'TRUE' && token !== null) {
      setOpen(true);
      setScroll(scrollType);
      setBookingPage(iFrameSource);
      setEquipmentName(iFrameTitle);
      setDetailedDescription(description);
      setProcedureDescription(procedure);
      setTrainingDescription(training);
      setEquipmentId(id);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Container maxWidth="lg" className={classes.container}>
        {/** Renderiza botão se usuário fizer parte do laboratório */}
        {profile && THIS_LAB_ID === profile.lab_id && (
          <Box className={classes.box}>
            <Button
              variant="contained"
              className={classes.new}
              onClick={handleEquipmentOpen('paper')}
            >
              <AddIcon />
            </Button>
          </Box>
        )}
        <Grid container spacing={3}>
          {/* {equipment &&
            equipment.map(item => (
              <Grid
                key={item.youcanbookpagetitle}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <MediaCard
                  title={`${item.nameofequipment} ${
                    item.namemanufacturer !== 'Sem registro'
                      ? item.namemanufacturer
                      : ''
                  } ${
                    item.modelequipment !== 'Sem registro'
                      ? item.modelequipment
                      : ''
                  }`}
                  description={item.shortdescription}
                  image={item.avatarurl}
                  bookingurl={item.bookingurl}
                  cardurl={item.cardurl}
                  bookingpageisactive={item.bookingpageisactive}
                  location={item.locationofequipment}
                  onClick={handleClickOpen(
                    'paper',
                    item.youcanbookpagetitle,
                    item.nameofequipment,
                    item.bookingpageisactive,
                    item.detaileddescription
                  )}
                  isDisabled={token === null}
                />
              </Grid>
            ))} */}
          {equipmentList &&
            equipmentList.map(
              item =>
                item.avatar && (
                  <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                    <MediaCard
                      title={`${item.equipment_name} ${
                        item.manufacturer !== 'Sem registro'
                          ? item.manufacturer
                          : ''
                      } ${item.model !== 'Sem registro' ? item.model : ''}`}
                      description={item.short_description}
                      image={item.avatar.url}
                      bookingurl={item.schedule_url}
                      bookingpageisactive
                      location={item.equipment_location_name}
                      onClick={
                        /** TEMPORÁRIO - ESTUDAR NOVA FORMA DE EXIBIR OU NAO EQUIPAMENTO */
                        handleClickOpen(
                          'paper',
                          item.schedule_url,
                          item.equipment_name,
                          item.state,
                          item.detailed_description,
                          item.procedure_description,
                          item.training_description,
                          item.id
                        )
                      }
                      isDisabled={token === null}
                    />
                  </Grid>
                )
            )}
        </Grid>
      </Container>
      <Dialog
        fullWidth
        maxWidth="md"
        classes={{ paperScrollPaper: classes.dialogPaper }}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle className={classes.title} id="scroll-dialog-title">
          {equipmentName}
        </DialogTitle>
        <DialogContent
          dividers={scroll === 'paper'}
          className={classes.content}
        >
          <Tabs
            labId={labId}
            equipmentId={equipmentId}
            iFrameSource={bookingPage}
            detailedDescription={detailedDescription}
            procedureDescription={procedureDescription}
            trainingDescription={trainingDescription}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={handleClose}
            color="primary"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        classes={{ paperScrollPaper: classes.dialogPaper }}
        fullScreen={fullScreen}
        open={createEquipmentOpen}
        onClose={handleEquipmentClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle className={classes.title} id="scroll-dialog-title">
          Cadastro de equipamento
        </DialogTitle>
        <DialogContent
          dividers={scroll === 'paper'}
          className={classes.content}
        >
          <CreateEquipment labId={labId} />
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={handleEquipmentClose}
            color="primary"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
