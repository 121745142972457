import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Formik, Form } from 'formik';
import useStyles from './styles';

import CheckoutSuccess from '../CheckoutSuccess';

import formModel from './FormContent/FormModel/model';
import formValidationSchema from './FormContent/FormModel/validationSchema';
import formInitialValues from './FormContent/FormModel/initialValues';

import FormContent from './FormContent';

const { formId, formSteps } = formModel;

export default function StepperForm() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === formSteps.length - 1;
  const currentValidationSchema = formValidationSchema[activeStep];

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    await _sleep(1000);
    alert(JSON.stringify(values, null, 2));
    actions.setSubmitting(false);

    setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <>
      <Typography component="h1" variant="h4" align="center">
        Solititação de Serviços
      </Typography>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {formSteps.map(step => (
          <Step key={step.title}>
            <StepLabel>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <>
        {activeStep === formSteps.length ? (
          <CheckoutSuccess />
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                <Form id={formId}>
                  {/** Render custom FormContent */}
                  <FormContent step={activeStep} />

                  {/** Render buttons */}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={_handleBack} className={classes.button}>
                        Voltar
                      </Button>
                    )}
                    <div className={classes.wrapper}>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        // onClick={_handleSubmit}
                      >
                        {isLastStep ? 'Enviar' : 'Próximo'}
                      </Button>
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </div>
                </Form>
              </MuiPickersUtilsProvider>
            )}
          </Formik>
        )}
      </>
    </>
  );
}
