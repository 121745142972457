import React from 'react';
import { Field } from 'formik';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';

export default function DateField(props) {
  return (
    <Field
      id={props.id}
      name={props.name}
      type={props.type}
      label={props.label}
      helperText={props.helperText}
      variant={props.variant}
      inputVariant={props.inputVariant}
      margin={props.margin}
      format={props.format}
      multiline={props.multiline}
      fullWidth={props.fullWidth}
      select={props.select}
      InputLabelProps={{
        shrink: true,
      }}
      component={KeyboardDatePicker}
    />
  );
}
