import checkoutFormModel from './checkoutFormModel';

const {
  formField: {
    firstName,
    email,
    phone,
    org,
    service,
    otherService,
    description,
    sector,
    city,
    knew,
    otherKnew,
  },
} = checkoutFormModel;

export default {
  [firstName.name]: '',
  [email.name]: '',
  [phone.name]: '',
  [org.name]: '',
  [service.name]: '',
  [otherService.name]: '',
  [description.name]: '',
  [sector.name]: '',
  [city.name]: '',
  [knew.name]: '',
  [otherKnew.name]: '',
};
