import React, { useState, useEffect } from 'react';

import clsx from 'clsx';
import { fade, makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import ReceiptIcon from '@material-ui/icons/Receipt';
import classNames from 'classnames';
import FeedbackIcon from '@material-ui/icons/Feedback';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import { signOut } from '~/store/modules/auth/actions';
import history from '~/services/history';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.primary.light,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  customBadge: {
    background: theme.palette.lab.secondary.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  drawerButton: {
    flexGrow: 0,
    color: theme.palette.peerlab.primary,
  },

  content: {
    flexGrow: 1,
    minWidth: drawerWidth,
    paddingTop: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    // display: 'none',
    // [theme.breakpoints.up('sm')]: {
    //   display: 'block',
    // },
    color: theme.palette.primary.main,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  avatar: {
    width: 35,
    height: 35,
    margin: 'auto',
  },
  accountIcon: {
    height: 35,
    widht: 35,
    borderRadius: '50%',
    borderColor: '#000',
    background: '#eee',
  },
  box: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  toolbar: {
    paddingRight: 0,
    paddingLeft: 0,
  },
}));

export default function DefaultLayout({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const labState = useSelector(state => state.lab.data);
  const profile = useSelector(state => state.user.profile);
  const preview = profile.avatar && profile.avatar.url;

  /** Variable to check if image source is valid */
  const [imgValid, setImgValid] = useState(false);

  /** Function that checks if image source is valid */
  function checkImage(imageSrc, good, bad) {
    const img = new Image();
    img.onload = good;
    img.onerror = bad;
    img.src = imageSrc;
  }

  /** Hook to call function and check validity of image */
  useEffect(() => {
    checkImage(
      preview,
      function() {
        setImgValid(true);
      },
      function() {
        setImgValid(false);
      }
    );
  }, [preview]);

  /** If there is a palette in state... */
  const useStateStyles = labState.palette
    ? makeStyles({
        /** ... Define complemantary style from state */
        appBarStateStyle: {
          backgroundColor: labState.palette.primary,
        },
        iconsStateStyle: {
          color:
            labState.palette.primary === theme.palette.primary.light
              ? theme.palette.primary.dark
              : theme.palette.primary.light,
        },
        buttonStateStyle: {
          padding: '5px 15px',
          border: `1px solid ${
            labState.palette.primary === theme.palette.primary.light
              ? theme.palette.primary.dark
              : theme.palette.primary.light
          }`,
          '&$disabled': {
            border: `1px solid ${theme.palette.action.disabledBackground}`,
          },
        },
        buttonTextStateStyle: {
          color:
            labState.palette.primary === theme.palette.primary.light
              ? theme.palette.primary.dark
              : theme.palette.primary.light,
        },
      })
    : /** ... Else, set complementary style to empty */
      makeStyles({ appBarStateStyle: {} });

  const stateClasses = useStateStyles();

  const firstMenuListItems = [
    {
      title: 'Início',
      icon: <HomeIcon />,
      href: token ? '/labs' : '/',
    },
    {
      title: 'Laboratórios',
      icon: <BusinessIcon />,
      href: '/labs',
    },
    // {
    //   title: 'Mais serviços',
    //   icon: <BuildIcon />,
    //   href: '/lmp',
    // },
    // {
    //   title: 'Mais equipamentos',
    //   icon: <RouterIcon />,
    //   href: '/',
    // },
    {
      title: 'Solicitar serviço',
      icon: <PostAddIcon />,
      href: '/solicitar-servico',
    },
  ];

  const secondMenuListItems = [
    // {
    //   title: 'Compartilhar',
    //   icon: <ShareIcon />,
    //   href: '/',
    // },
    {
      title: 'Criar orçamento',
      icon: <ReceiptIcon />,
      href: '/orcamento',
    },
    // {
    //   title: 'Novo equipamento',
    //   icon: <AddBoxIcon />,
    //   href: '/new-equipment',
    // },
    {
      title: 'Enviar feedback',
      icon: <FeedbackIcon />,
      href: '/feedback',
    },
  ];

  /**
   * Define initial state based on viewport
   */
  const [open, setOpen] = React.useState(window.innerWidth > 500);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  /** Navega usuario para pagina adequada */
  const handleNavigate = route => () => {
    /** Se usuário estiver em um iFrame... */
    if (window.self !== window.top) {
      /** Redireciona página superior para rota desejada, porém no domínio peerlab.com.br */
      window.top.location.href = `https://www.peerlab.com.br${route}`;
    } else {
      history.push(route);
    }
    /** Fecha drawer caso esteja aberto */
    if (open) {
      handleDrawerClose();
    }

    if (anchorEl) {
      handleMenuClose();
    }
  };

  /** Caso usuário esteja navegando em iFrame, faz logout */
  useEffect(() => {
    if (window.self !== window.top) {
      /** Redireciona página superior para rota desejada, porém no domínio peerlab.com.br */
      dispatch(signOut());
    }
  });

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleNavigate('/profile')}>Perfil</MenuItem>
      <MenuItem className={classes.hide} onClick={handleMenuClose}>
        Minha conta
      </MenuItem>
      <MenuItem onClick={handleSignOut}>Sair</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem className={classes.hide}>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} classes={{ badge: classes.customBadge }}>
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem className={classes.hide}>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} classes={{ badge: classes.customBadge }}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          {imgValid ? (
            <Avatar
              className={classes.avatar}
              alt="avatar"
              src={
                preview ||
                'https://trello-attachments.s3.amazonaws.com/5f69270e6958b76962f7db1e/400x400/266ff5366907b2ef2e5410ebccb560cc/profile.jpg'
              }
            />
          ) : (
            <Avatar
              className={classes.avatar}
              alt="avatar"
              src="https://trello-attachments.s3.amazonaws.com/5f69270e6958b76962f7db1e/400x400/266ff5366907b2ef2e5410ebccb560cc/profile.jpg"
            />
          )}
        </IconButton>
        <p>Minha conta</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(
          classes.appBar,
          {
            [classes.appBarShift]: open,
          },
          stateClasses.appBarStateStyle
        )}
      >
        <Box className={classes.box}>
          <Container maxWidth="lg" className={classes.container}>
            <Toolbar className={classes.toolbar}>
              <IconButton
                aria-label="open drawer"
                onClick={!open ? handleDrawerOpen : handleDrawerClose}
                edge="start"
                className={clsx(
                  classes.menuButton,

                  // open && classes.hide,
                  stateClasses.iconsStateStyle
                )}
              >
                <MenuIcon />
              </IconButton>
              <Button className={classes.title} href={token ? '/labs' : '/'}>
                <Typography
                  className={clsx(classes.title, stateClasses.iconsStateStyle)}
                  variant="h6"
                  noWrap
                >
                  {labState.name}
                </Typography>
              </Button>

              <div className={classNames(classes.search, classes.hide)}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <IconButton
                  className={classes.hide}
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <Badge badgeContent={4} color="secondary">
                    <MailIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  className={classes.hide}
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge badgeContent={17} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  className={clsx(
                    classes.menuButton,
                    stateClasses.iconsStateStyle
                  )}
                >
                  {imgValid ? (
                    <Avatar
                      className={classes.avatar}
                      alt="avatar"
                      src={
                        preview ||
                        'https://trello-attachments.s3.amazonaws.com/5f69270e6958b76962f7db1e/400x400/266ff5366907b2ef2e5410ebccb560cc/profile.jpg'
                      }
                    />
                  ) : (
                    <Avatar
                      className={classes.avatar}
                      alt="avatar"
                      src="https://trello-attachments.s3.amazonaws.com/5f69270e6958b76962f7db1e/400x400/266ff5366907b2ef2e5410ebccb560cc/profile.jpg"
                    />
                  )}
                </IconButton>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  className={stateClasses.iconsStateStyle}
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Toolbar>
          </Container>
        </Box>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Button
            className={classes.drawerButton}
            fullWidth
            onClick={handleNavigate('/')}
          >
            <Typography variant="h5" noWrap>
              PEERLAB
            </Typography>
          </Button>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {firstMenuListItems.map(item => (
            <ListItem
              button
              key={item.title}
              onClick={handleNavigate(item.href)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {secondMenuListItems.map(item => (
            <ListItem
              button
              key={item.title}
              onClick={handleNavigate(item.href)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
}
