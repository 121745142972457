import * as Yup from 'yup';
import model from './model';

/** Get formSteps from model */
const { formSteps } = model;

const validationObjects = [];

/** For each step in formSteps */
formSteps.forEach(step => {
  /** Create validation Object */
  const validationObject = {};

  const { formFields } = step;

  /** For each field in the step */
  formFields.forEach(field => {
    /** Define property equals to field name and value equals to validation */
    validationObject[field.name] = field.validation;

    /** If there is a 'other' field, adds field validation */
    if (field.other && field.other.name) {
      validationObject[field.other.name] = field.other.validation;
    }
  });

  /** Adds object to validationObjects list */
  validationObjects.push(Yup.object().shape(validationObject));
});

/** Export list of validation objects */
export default validationObjects;
