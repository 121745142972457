import axios from 'axios';

require('dotenv/config');

const api = axios.create({

  // Development:
  // baseURL: 'http://localhost:3333',

  // Production
  baseURL: process.env.REACT_APP_BASE_URL, // 'https://peerlab.tk'
});

export default api;
