import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Avatar } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { updateProfileRequest } from '~/store/modules/user/actions';
import { setLabState } from '~/store/modules/lab/actions';
import api from '~/services/api';
import { useStyles } from './styles';

export default function Profile({ postRoute }) {
  const classes = useStyles();
  const profile = useSelector(state => state.user.profile);
  const [userData, setUserData] = useState(null);
  const [file, setFile] = useState(profile.avatar && profile.avatar.id);
  const [preview, setPreview] = useState(profile.avatar && profile.avatar.url);
  const [imageIsValid, setImageIsValid] = useState(false);
  const labName = 'PEERLAB';
  const dispatch = useDispatch();

  const fetchDataAsync = async () => {
    const response = await api.get('/users');
    setUserData(response.data);
  };

  useEffect(() => {
    dispatch(setLabState({ labName }));
  }, [dispatch]);

  useEffect(() => {
    fetchDataAsync();
  }, []);

  const handleSubmit = avatarId => async (values, { resetForm }) => {
    const avatar_id = avatarId;
    dispatch(updateProfileRequest({ avatar_id }));
    setUserData(values);
    resetForm({ values: '' });
  };

  /** Function that checks if image source is valid */
  function checkImage(imageSrc, good, bad) {
    const img = new Image();
    img.onload = good;
    img.onerror = bad;
    img.src = imageSrc;
  }

  /** Hook to call function and check validity of image */
  useEffect(() => {
    checkImage(
      preview,
      function() {
        setImageIsValid(true);
      },
      function() {
        setImageIsValid(false);
      }
    );
  }, [preview]);

  const handleChange = formProps => async e => {
    formProps.setFieldValue('avatar', e.target.files[0]);
    const data = new FormData();

    /** Adiciona arquivo à requisição (multipart form data) com o nome 'file' esperado no backend */
    data.append('file', e.target.files[0]);

    /** Envia requisição para rota files */
    const response = await api.post(postRoute, data);

    /** Salva resposta da requisição */
    const { id: avatar_id, url } = response.data;

    setFile(avatar_id);
    setPreview(url);

    dispatch(updateProfileRequest({ avatar_id }));
  };

  return (
    <>
      {userData && (
        <div className={classes.paper}>
          <Formik
            enableReinitialize
            initialValues={{
              avatar: '',
            }}
            onSubmit={handleSubmit(file)}
          >
            {formProps => (
              <Form>
                <Container align="center">
                  <label htmlFor="avatar" className={classes.label}>
                    {/** Avalia se imagem é válida e renderiza avatar */}
                    {imageIsValid ? (
                      <Avatar
                        className={classes.avatar}
                        alt="avatar"
                        src={
                          preview ||
                          'https://trello-attachments.s3.amazonaws.com/5f69270e6958b76962f7db1e/400x400/266ff5366907b2ef2e5410ebccb560cc/profile.jpg'
                        }
                      />
                    ) : (
                      <Avatar
                        className={classes.avatar}
                        alt="avatar"
                        src="https://trello-attachments.s3.amazonaws.com/5f69270e6958b76962f7db1e/400x400/266ff5366907b2ef2e5410ebccb560cc/profile.jpg"
                      />
                    )}
                    <input
                      className={classes.input}
                      id="avatar"
                      name="avatar"
                      type="file"
                      onChange={handleChange(formProps)}
                    />
                  </label>
                </Container>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}
