import React from 'react';
import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from '~/components/Copyright';

import CustomForm from './CustomForm';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(6),
  },
}));

export default function EquipmentForm() {
  const classes = useStyles();

  return (
    <>
      <Container component="main" maxWidth="sm" className={classes.paper}>
        <CustomForm />
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}
