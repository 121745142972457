import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    borderRadius: 10,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    width: '100%',
  },
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  const [raised, setRaised] = React.useState(false);

  const toggleRaised = () => setRaised(!raised);

  return (
    <Card
      className={classes.root}
      onMouseOver={toggleRaised}
      onMouseOut={toggleRaised}
      raised={raised}
    >
      <CardActionArea href={`labs/${props.route}`}>
        <CardMedia
          className={classes.media}
          image={props.mediaImage}
          title={props.mediaTitle}
        />
        <CardContent id="equipContent">
          <Typography variant="subtitle1" component="h1" align="center">
            {props.title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
