import styled from 'styled-components';

export const Wrapper = styled.div`
  #title {
    font-weight: 600;
  }
  #subtitle {
    font-weight: 400;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  margin: 15% auto auto auto;
  max-width: 650px;

  @media (max-width: 720px) {
    flex-direction: column;
    img {
      object-fit: contain !important;
      margin-bottom: 5%;
      height: 210px !important;
    }
  }

  #right-content {
    margin-top: 2%;
  }
  img {
    object-fit: cover;
    height: 250px;
    border-radius: 4px;
    margin-right: 90px;
    margin-left: 40px;
  }
`;
