import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import LinesEllipsis from 'react-lines-ellipsis';
import LocationOnIcon from '@material-ui/icons/LocationOn';

export default function MediaCard(props) {
  const [raised, setRaised] = React.useState(false);
  const toggleRaised = () => setRaised(!raised);
  const labState = useSelector(state => state.lab.data);

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      borderRadius: 10,
    },
    action: {
      display: 'flex',
      flexDirection: 'row',
    },
    media: {
      height: 140,
      flex: '0 0 auto',
    },
    content: {
      overflow: 'hidden',
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(0),
    },
    button: {
      flexGrow: 1,
      whiteSpace: 'no-wrap',
    },
    description: {
      height: theme.spacing(18),
      marginBottom: theme.spacing(1),
    },
    grow: {
      flexGrow: 1,
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    rating: {
      marginBottom: theme.spacing(2),
    },
    location: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    locationIcon: {
      color: labState.palette.primary,
      padding: 0,
      marginRight: theme.spacing(1),
      marginLeft: -5,
    },
  }));

  const classes = useStyles();

  return (
    <CardActionArea onClick={props.onClick}>
      <Card
        classes={{ root: classes.root }}
        onMouseOver={toggleRaised}
        onMouseOut={toggleRaised}
        raised={raised}
      >
        <CardMedia className={classes.media} image={props.image} />

        <CardContent className={classes.content}>
          <div className={classes.description}>
            <Typography gutterBottom component="h2">
              <LinesEllipsis
                text={props.title}
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </Typography>
            <Typography
              gutterBottom
              classes={{ root: classes.location }}
              color="textSecondary"
            >
              <LocationOnIcon
                fontSize="small"
                className={classes.locationIcon}
              />
              {props.location}
            </Typography>

            <Typography
              gutterBottom
              component="div"
              variant="body2"
              color="textSecondary"
            >
              <LinesEllipsis
                text={props.description}
                maxLine="3"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </Typography>
          </div>
        </CardContent>
      </Card>
    </CardActionArea>
  );
}
