import React from 'react';
import { Typography, Button } from '@material-ui/core';
import useStyles from '../styles.js';

function CheckoutSuccess() {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Solicitação enviada!
      </Typography>
      <Typography variant="subtitle1" align="justify">
        Sua solicitação já está registrada e será avaliada e redirecionada ao
        laboratório adequado e este irá preparar um orçamento. Esse orçamento
        será enviado a você em forma de proposta dentro de alguns dias.
      </Typography>
      <Button
        href="/"
        variant="contained"
        color="primary"
        className={classes.button}
      >
        Página inicial
      </Button>
    </>
  );
}

export default CheckoutSuccess;
