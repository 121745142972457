import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import LoggedOut from '~/pages/_layouts/LoggedOut';
import LoggedIn from '~/pages/_layouts/LoggedIn';
import { store } from '~/store';

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  isSign = false,
  isPublicOnly = false,
  ...rest
}) {
  const { signed } = store.getState().auth;

  if (isSign) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  }

  if (!signed && isPrivate) {
    return <Redirect to="/signin" />;
  }

  if (signed && isPublicOnly) {
    return <Redirect to="/labs" />;
  }

  const Layout = signed ? LoggedIn : LoggedOut;

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isSign: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isSign: false,
};
