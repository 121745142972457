import React from 'react';
import Carousel from 'react-material-ui-carousel';
import Item from './Item';

export default function CarouselContainer({ items }) {
  return (
    <Carousel interval={6000}>
      {items && items.map((item, index) => <Item key={index} item={item} />)}
    </Carousel>
  );
}
