import React from 'react';
import { useFormikContext } from 'formik';
import { Typography, Grid } from '@material-ui/core';
import DescriptionDetails from './DescriptionDetails';
import ContactDetails from './ContatcDetails';

export default function ReviewOrder() {
  const { values: formValues } = useFormikContext();
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Revisão da solicitação:
      </Typography>
      <DescriptionDetails formValues={formValues} />
      <ContactDetails formValues={formValues} />

      <Grid container spacing={2} />
    </>
  );
}
