import * as Yup from 'yup';
import { InputField, SelectField, FileUpload } from '../FieldModels';

export default {
  formId: 'createEquipment',
  formSteps: [
    {
      title: '',
      formFields: [
        {
          get id() {
            return this.name;
          },
          label: '',
          name: 'avatar_id',
          type: 'text',
          component: FileUpload,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Laboratório*',
          name: 'lab_id',
          type: 'text',
          component: SelectField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [
            {
              label: 'CERMAT',
              value: 3,
            },
            {
              label: 'LMP',
              value: 2,
            },
          ],
          other: {},
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Categoria*',
          name: 'category',
          type: 'text',
          component: SelectField,
          helperText: '',
          requiredErrorMsg: 'Informe a categoria do equipamento',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [
            {
              value:
                'Fabricação, processamento e transformação de matéria prima',
              label:
                'Fabricação, processamento e transformação de matéria prima',
            },
            {
              value: 'Medição, análise e ensaio',
              label: 'Medição, análise e ensaio',
            },
            {
              value: 'Manuseio, transporte e movimentação',
              label: 'Manuseio, transporte e movimentação',
            },
            {
              value: 'Montagem e ajuste',
              label: 'Montagem e ajuste',
            },
          ],
          other: {},
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Nome do equipamento*',
          name: 'equipment_name',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: 'Informe o nome do equipamento',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Descrição breve*',
          name: 'short_description',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: 'Faça uma breve descrição do uso do equipamento',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
      ],
    },
    {
      title: ' ',
      formFields: [
        {
          get id() {
            return this.name;
          },
          label: 'Marca',
          name: 'manufacturer',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Modelo',
          name: 'model',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Palavras-chave',
          name: 'keywords',
          type: 'text',
          component: InputField,
          helperText: 'Use vírgulas para separar as palavras',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Estado',
          name: 'state',
          type: 'text',
          component: SelectField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [
            {
              label: 'Disponível',
              value: 'TRUE',
            },
            {
              label: 'Indisponível',
              value: 'FALSE',
            },
          ],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Descrição detalhada',
          name: 'detailed_description',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: true,
          options: [],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
      ],
    },
    {
      title: '  ',
      formFields: [
        {
          get id() {
            return this.name;
          },
          label: 'Localização atual',
          name: 'equipment_location_name',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Página de agendamento',
          name: 'schedule_url',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Especificações técnicas',
          name: 'specification_description',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: true,
          options: [],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Procedimento operacional',
          name: 'procedure_description',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: true,
          options: [],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Treinamentos',
          name: 'training_description',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: true,
          options: [],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
      ],
    },
  ],
};
