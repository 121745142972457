export function setLabState(data) {
  return {
    type: '@lab/SET_LAB_STATE',
    payload: { data },
  };
}

export function getLabEquipmentRequest(data) {
  return {
    type: '@lab/GET_LAB_EQUIPMENT_REQUEST',
    payload: { data },
  };
}

export function getLabEquipmentSuccess(data) {
  return {
    type: '@lab/GET_LAB_EQUIPMENT_SUCCESS',
    payload: { data },
  };
}

export function getLabEquipmentFailure() {
  return {
    type: '@lab/GET_LAB_EQUIPMENT_FAILURE',
  };
}
