import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { TeamCard } from './TeamCard';
import api from '~/services/api';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: 'scroll',
    height: '100%',
  },
  container: {
    paddingBottom: theme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default function TeamMembers({ labAgentName }) {
  const classes = useStyles();

  const [teamMembers, setTeamMembers] = useState(undefined);

  const fetchApiDataAsync = useCallback(async () => {
    const result = await api.get(`/labs/${labAgentName}/team`);
    setTeamMembers(result.data);
  }, [labAgentName]);

  useEffect(() => {
    fetchApiDataAsync();
  }, [fetchApiDataAsync]);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container classes={classes.root} spacing={3}>
        {teamMembers &&
          teamMembers.map(item => {
            return (
              item.position &&
              item.position.includes('Coordenador') && (
                <Grid
                  key={`${item.name} ${item.last_name}`}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                >
                  <TeamCard
                    name={`${item.name} ${item.last_name}`}
                    description={item.position}
                    ImgSrc={item.avatar && item.avatar.url}
                    linkedinUrl={item.linkedin_url}
                  />
                </Grid>
              )
            );
          })}
        {teamMembers &&
          teamMembers.map(item => {
            return (
              item.position &&
              item.position.includes('Professor') &&
              !item.position.includes('Coordenador') && (
                <Grid
                  item
                  key={`${item.name} ${item.last_name}`}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                >
                  <TeamCard
                    name={`${item.name} ${item.last_name}`}
                    description={item.position}
                    ImgSrc={item.avatar && item.avatar.url}
                    linkedinUrl={item.linkedin_url}
                  />
                </Grid>
              )
            );
          })}
        {teamMembers &&
          teamMembers.map(item => {
            return (
              item.position &&
              item.position.startsWith('Técnico') && (
                <Grid
                  item
                  key={`${item.name} ${item.last_name}`}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                >
                  <TeamCard
                    name={`${item.name} ${item.last_name}`}
                    description={item.position}
                    ImgSrc={item.avatar && item.avatar.url}
                    linkedinUrl={item.linkedin_url}
                  />
                </Grid>
              )
            );
          })}

        {teamMembers &&
          teamMembers.map(item => {
            return (
              item.position &&
              item.position.startsWith('Pós') && (
                <Grid
                  item
                  key={`${item.name} ${item.last_name}`}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                >
                  <TeamCard
                    name={`${item.name} ${item.last_name}`}
                    description={item.position}
                    ImgSrc={item.avatar && item.avatar.url}
                    linkedinUrl={item.linkedin_url}
                  />
                </Grid>
              )
            );
          })}

        {teamMembers &&
          teamMembers.map(item => {
            return (
              item.position &&
              item.position.startsWith('Doutorado') && (
                <Grid
                  item
                  key={`${item.name} ${item.last_name}`}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                >
                  <TeamCard
                    name={`${item.name} ${item.last_name}`}
                    description={item.position}
                    ImgSrc={item.avatar && item.avatar.url}
                    linkedinUrl={item.linkedin_url}
                  />
                </Grid>
              )
            );
          })}

        {teamMembers &&
          teamMembers.map(item => {
            return (
              item.position &&
              item.position.startsWith('Mestrado') && (
                <Grid
                  item
                  key={`${item.name} ${item.last_name}`}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                >
                  <TeamCard
                    name={`${item.name} ${item.last_name}`}
                    description={item.position}
                    ImgSrc={item.avatar && item.avatar.url}
                    linkedinUrl={item.linkedin_url}
                  />
                </Grid>
              )
            );
          })}

        {teamMembers &&
          teamMembers.map(item => {
            return (
              item.position &&
              item.position.startsWith('Graduação') && (
                <Grid
                  item
                  key={`${item.name} ${item.last_name}`}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                >
                  <TeamCard
                    name={`${item.name} ${item.last_name}`}
                    description={item.position}
                    ImgSrc={item.avatar && item.avatar.url}
                    linkedinUrl={item.linkedin_url}
                  />
                </Grid>
              )
            );
          })}
      </Grid>
    </Container>
  );
}
