import React from 'react';
// import moment from 'moment';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';

function DescriptionDetails(props) {
  const { formValues } = props;
  const classes = useStyles();
  const { service, sector, description, otherService } = formValues;

  function serviceSelected(services) {
    switch (services) {
      case '1':
        return 'Serviços de consultoria e treinamentos';
      case '2':
        return 'Projetos de componentes e equipamentos especiais';
      case '3':
        return 'Fabricação de componentes e ensaios de fabricação';
      case '4':
        return 'Ensaios, certificação e análise de amostras';
      case '5':
        return 'Outro';
      default:
        return otherService;
    }
  }
  function sectorSelected(sectors) {
    switch (sectors) {
      case '1':
        return 'Academia';
      case '2':
        return 'Mercado';
      case '3':
        return 'Edital';
      default:
        return 'Not Found';
    }
  }
  return (
    <Grid item container direction="column" xs={12}>
      <Typography variant="h6" gutterBottom className={classes.title}>
        Detalhes do serviço:
      </Typography>
      <Grid container>
        <>
          <Grid item xs={4}>
            <Typography gutterBottom>Tipo:</Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography gutterBottom>{serviceSelected(service)}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography gutterBottom>Descrição:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography gutterBottom>{description}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography gutterBottom>Setor:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography gutterBottom>{sectorSelected(sector)}</Typography>
          </Grid>
        </>
      </Grid>
    </Grid>
  );
}

export default DescriptionDetails;
