import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, RadioField } from '../../FormFields';

const options = [
  {
    value: '1',
    label: 'Google',
  },
  {
    value: '2',
    label: 'Instagram',
  },
  {
    value: '3',
    label: 'Linkedin',
  },
  {
    value: '4',
    label: 'Ex-aluno',
  },
  {
    value: '5',
    label: 'Professor',
  },
  {
    value: '6',
    label: 'Indicação',
  },
];

export default function AddressForm(props) {
  const {
    formField: { firstName, email, phone, org, city, knew, otherKnew },
  } = props;
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Dados Pessoais
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputField name={firstName.name} label={firstName.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={email.name} label={email.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={phone.name} label={phone.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={org.name} label={org.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField name={city.name} label={city.label} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <RadioField
            name={knew.name}
            label={knew.label}
            data={options}
            other
            otherName={otherKnew.name}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}
