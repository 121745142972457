import React from 'react';
// import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import { TextField /* , CheckboxWithLabel */ } from 'formik-material-ui';
import * as Yup from 'yup';
import { CircularProgress } from '@material-ui/core';
import { signUpRequest } from '~/store/modules/auth/actions';
import Message from '../../components/LoadingMessage';
import Copyright from '~/components/Copyright';

// const sleep = time => new Promise(acc => setTimeout(acc, time));

const SignUpSchema = Yup.object().shape({
  name: Yup.string().required('Informe seu nome'),
  surname: Yup.string().required('Informe seu sobrenome'),
  agent_name: Yup.string().required('Informe um username'),
  email: Yup.string()
    .email('Informe um e-mail válido')
    .required('Informe seu e-mail'),
  password: Yup.string()
    .required('Informe sua senha')
    .min(6, 'Senha deve ter no mínimo 6 dígitos'),
});

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.light,
    margin: theme.spacing(2, 0, 2),
    width: '100%',
  },
}));

export default function SignUp() {
  const classes = useStyles();
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  async function handleSubmit({ name, surname, email, password, agent_name }) {
    /** Define submiting state equals to true */
    // setIsSubmitting(true);

    /**
     * IMPORTANT!!!!
     * Dispara action signInRequest
     */
    dispatch(signUpRequest(name, surname, email, password, agent_name));

    /** Return to normal state */
    // setIsSubmitting(false);

    /** EXAMPLE CODE
     * setIsSubmitting(true);
     * await sleep(1000);
     * console.tron.log({ email, password });
     * setIsSubmitting(false);
     */
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Crie sua conta
        </Typography>
        <Formik
          initialValues={{
            name: '',
            surname: '',
            email: '',

            password: '',
            agent_name: '',
            maillist: false,
          }}
          validationSchema={SignUpSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Field
              id="name"
              name="name"
              label="Nome"
              autoComplete="name"
              variant="outlined"
              margin="normal"
              fullWidth
              component={TextField}
            />
            <Field
              id="surname"
              name="surname"
              type="surname"
              label="Sobrenome"
              autoComplete="surname"
              variant="outlined"
              margin="normal"
              fullWidth
              component={TextField}
            />
            <Field
              id="email"
              name="email"
              type="email"
              label="Email"
              autoComplete="email"
              variant="outlined"
              margin="normal"
              fullWidth
              component={TextField}
            />
            <Field
              id="agent_name"
              name="agent_name"
              type="text"
              label="Username"
              variant="outlined"
              margin="normal"
              fullWidth
              component={TextField}
            />
            <Field
              id="password"
              name="password"
              type="password"
              label="Senha"
              autoComplete="password"
              variant="outlined"
              margin="normal"
              fullWidth
              component={TextField}
            />
            {/* <Field
              name="maillist"
              type="checkbox"
              autoComplete="maillist"
              component={CheckboxWithLabel}
              Label={{
                label: 'Receber notificações e atualizações por email.',
              }}
            />

            */}
            {loading ? <Message /> : null}

            <Button
              startIcon={loading ? <CircularProgress size="1rem" /> : null}
              disabled={loading}
              type="submit"
              variant="contained"
              className={classes.submit}
            >
              {loading ? 'Criando Conta' : 'Criar Conta'}
            </Button>
            <Grid item xs style={{ textAlign: 'center' }}>
              <Link href="/signin" variant="body2">
                Já sou cadastrado? Entrar
              </Link>
            </Grid>
          </Form>
        </Formik>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
