import * as Yup from 'yup';
import {
  InputField,
  RadioField,
  SelectField,
  DateField,
} from '../../FormFields';

import ReviewOrder from '../../../ReviewOrder';

export default {
  formId: 'checkoutForm',
  formSteps: [
    {
      title: 'Serviço',
      formFields: [
        {
          get id() {
            return this.name;
          },
          label: 'Setor*',
          name: 'sector',
          type: 'text',
          component: SelectField,
          helperText: '',
          requiredErrorMsg: 'Selecione o setor de origem da demanda',
          invalidErrorMsg: 'Invalid',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [
            { label: 'Academia', value: 'Academia' },
            { label: 'Mercado', value: 'Mercado' },
            { label: 'Edital', value: 'Edital' },
          ],
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Tipo de Serviço*',
          name: 'service',
          type: '',
          component: RadioField,
          helperText: '',
          requiredErrorMsg: 'Selecione o tipo serviço desejado',
          invalidErrorMsg: 'Invalid',
          variant: 'outlined',
          fullWidth: true,
          margin: 'normal',
          multiline: false,
          options: [
            {
              value: '1',
              label: 'Serviços de consultoria e treinamentos',
            },
            {
              value: '2',
              label: 'Projetos de componentes e equipamentos especiais',
            },
            {
              value: '3',
              label: 'Fabricação de componentes e ensaios de fabricação',
            },
            {
              value: '4',
              label: 'Ensaios, certificação e análise de amostras',
            },
          ],
          other: {
            get id() {
              return this.name;
            },
            label: '',
            name: 'otherService',
            type: 'text',
            active: true,
            component: InputField,
            helperText: '',
            requiredErrorMsg: '',
            invalidErrorMsg: '',
            variant: undefined,
            fullWidth: false,
            margin: undefined,
            multiline: false,
            select: false,
            get validation() {
              return Yup.string().nullable();
            },
          },
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Data*',
          name: 'description',
          type: 'date',
          component: DateField,
          helperText: '',
          requiredErrorMsg: 'Forneça uma descrição do serviço desejado',
          invalidErrorMsg: 'Invalid',
          variant: 'outlined',
          fullWidth: true,
          margin: 'normal',
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
      ],
    },
    {
      title: 'Dados',
      formFields: [
        {
          get id() {
            return this.name;
          },
          label: 'Nome*',
          name: 'firstName',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: 'O nome é obrigatório',
          invalidErrorMsg: 'Invalid',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Email*',
          name: 'email',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: 'O email é obrigatório',
          invalidErrorMsg: 'Informe um endereço de email válido',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string()
              .email(this.invalidErrorMsg)
              .required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Telefone*',
          name: 'phone',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: 'O telefone é obrigatório',
          invalidErrorMsg: 'Digite um número',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.number()
              .typeError(this.invalidErrorMsg)
              .required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Organização',
          name: 'org',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: 'Required',
          invalidErrorMsg: 'Invalid',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Cidade*',
          name: 'city',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: 'Selecione a cidade',
          invalidErrorMsg: 'Invalid',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Como soube do Laboratório?*',
          name: 'knew',
          type: '',
          component: RadioField,
          helperText: '',
          requiredErrorMsg: 'Selecione uma das opções',
          invalidErrorMsg: 'Invalid',
          variant: 'outlined',
          fullWidth: true,
          margin: 'normal',
          multiline: false,
          options: [
            {
              value: '1',
              label: 'Google',
            },
            {
              value: '2',
              label: 'Instagram',
            },
            {
              value: '3',
              label: 'Linkedin',
            },
            {
              value: '4',
              label: 'Ex-aluno',
            },
            {
              value: '5',
              label: 'Professor',
            },
            {
              value: '6',
              label: 'Indicação',
            },
          ],
          other: {
            get id() {
              return this.name;
            },
            label: '',
            name: 'otherKnew',
            type: 'text',
            active: true,
            component: InputField,
            helperText: '',
            requiredErrorMsg: '',
            invalidErrorMsg: '',
            variant: undefined,
            fullWidth: false,
            margin: undefined,
            multiline: false,
            select: false,
            get validation() {
              return Yup.string().nullable();
            },
          },
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
      ],
    },
    {
      title: 'Envio',
      formFields: [
        {
          get id() {
            return this.name;
          },
          label: 'Envio',
          name: 'send',
          type: 'text',
          component: ReviewOrder,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: '',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return undefined;
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
      ],
    },
  ],
};
