import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import IFrame from './IFrame';
import UpdateEquipment from './UpdateEquipment';

function TabPanel(props) {
  const { children, value, index, p, ...other } = props;

  const useStyles = makeStyles(() => ({
    root: {
      justifyContent: 'center',
      height: 'calc(100% - 48px)',
    },
    box: {
      height: '100%',
    },
  }));

  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
      className={classes.root}
    >
      {value === index && (
        <Box className={classes.box} p={p}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    flex: '1 0 100%',
    width: '100%',

    backgroundColor: theme.palette.background.paper,
  },
  scroller: {
    flexGrow: '0',
  },
  customIndicator: {
    backgroundColor: theme.palette.primary.main,
  },
  customSelected: {
    color: theme.palette.primary.main,
  },
}));

export default function ScrollableTabsButtonAuto({
  labId,
  equipmentId,
  iFrameSource,
  detailedDescription,
  procedureDescription,
  trainingDescription,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  /** Chave de acesso do laboratorio dono desta pagina ---- TEMPORARIO */
  const thisLabId = labId;

  /** Busca id do laboratorio do usuario */
  const userLabId = useSelector(
    state => state.user.profile && state.user.profile.lab_id
  );

  /** Confere se usuario tem acesso a informacoes privadas do laboratorio */
  const hasPrivateAccess = thisLabId === userLabId;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const labState = useSelector(state => state.lab.data);
  /** If there is a palette in state... */
  const useStateStyles = labState.palette
    ? makeStyles(theme => ({
        /** ... Define complemantary style from state */
        customIndicatorStateStyle: {
          backgroundColor: labState.palette.primary,
        },
        customSelectedStateStyle: {
          color: theme.palette.primary.light,
        },
      }))
    : /** ... Else, set complementary style to empty */
      makeStyles({ appBarStateStyle: {} });

  const stateClasses = useStateStyles();

  return (
    <div className={classes.root}>
      <AppBar position="sticky" color="default">
        <Tabs
          classes={{
            root: classes.root,
            scroller: classes.scroller,
            indicator: clsx(
              classes.customIndicator,
              stateClasses.customIndicatorStateStyle
            ),
          }}
          value={value}
          onChange={handleChange}
          // indicatorColor="primary"
          // textColor="primary"
          variant="scrollable"
          scrollButtons="on"
        >
          <Tab
            label="Especificações"
            {...a11yProps(0)}
            classes={{
              selected: classes.customSelected,
            }}
          />

          {hasPrivateAccess && (
            <Tab
              label="Agenda"
              {...a11yProps(1)}
              classes={{
                selected: classes.customSelected,
              }}
            />
          )}
          {hasPrivateAccess && (
            <Tab
              label="Procedimentos"
              {...a11yProps(2)}
              classes={{
                selected: classes.customSelected,
              }}
            />
          )}
          {hasPrivateAccess && (
            <Tab
              label="Treinamentos"
              {...a11yProps(3)}
              classes={{
                selected: classes.customSelected,
              }}
            />
          )}
          {hasPrivateAccess && (
            <Tab
              label="Configurações"
              {...a11yProps(4)}
              classes={{
                selected: classes.customSelected,
              }}
            />
          )}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} p={3}>
        <Typography>{detailedDescription}</Typography>
      </TabPanel>
      {hasPrivateAccess && (
        <TabPanel value={value} index={1} p={0}>
          <IFrame iFrameSource={iFrameSource} />
        </TabPanel>
      )}
      {hasPrivateAccess && (
        <TabPanel value={value} index={2} p={3}>
          <Typography>{procedureDescription}</Typography>
        </TabPanel>
      )}
      {hasPrivateAccess && (
        <TabPanel value={value} index={3} p={3}>
          <Typography>{trainingDescription}</Typography>
        </TabPanel>
      )}
      {hasPrivateAccess && (
        <TabPanel value={value} index={4} p={3}>
          <UpdateEquipment equipmentId={equipmentId} />
        </TabPanel>
      )}
    </div>
  );
}
