import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  stepper: {
    padding: theme.spacing(5, 0, 3),
    backgroundColor: 'transparent',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonNext: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.light,
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  wrapper: {
    margin: theme.spacing(3, 0, 3, 3),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}));
