import produce from 'immer';

const INITIAL_STATE = {
  profile: null,
  loading: false,
};

export default function equipment(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@equipment/CREATE_EQUIPMENT_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@equipment/CREATE_EQUIPMENT_SUCCESS': {
        draft.profile = action.payload.profile;
        draft.loading = false;
        break;
      }
      case '@equipment/CREATE_EQUIPMENT_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@equipment/GET_CURRENT_EQUIPMENT_DATA_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@equipment/GET_CURRENT_EQUIPMENT_DATA_SUCCESS': {
        draft.profile = action.payload.data;
        draft.loading = false;
        break;
      }
      case '@equipment/GET_CURRENT_EQUIPMENT_DATA_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@equipment/UPDATE_EQUIPMENT_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@equipment/UPDATE_EQUIPMENT_SUCCESS': {
        draft.profile = action.payload.data.updatedEquipment;
        draft.loading = false;
        break;
      }
      case '@equipment/UPDATE_EQUIPMENT_FAILURE': {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
