import React from 'react';
import { Grid } from '@material-ui/core';
import formModel from '../FormModel/model';
import Field from '../FieldModels/Field';

/** Destructure form steps from formModel */
const { formSteps } = formModel;

export default function FormContent({ step, setFieldValue }) {
  const stepFields = formSteps[step].formFields;
  return (
    <>
      <Grid container spacing={3}>
        {stepFields.map(field => {
          return (
            <Grid key={field.name} item xs={12}>
              <Field formField={field} setFieldValue={setFieldValue} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
