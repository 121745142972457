import React from 'react';
import Radio from '@material-ui/core/Radio';
import { RadioGroup } from 'formik-material-ui';
import { at } from 'lodash';
import { useField, Field } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import OtherField from './Field';

export default function RadioField(props) {
  const { label, options, other, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  const { setValue } = helper;
  const [enableText, setEnableText] = React.useState(true);

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  function handleChange(event) {
    setValue(event.target.value);
    if (event.target.value === 'outro') setEnableText(false);
    else setEnableText(true);
  }

  return (
    <FormControl component="fieldset" {...rest} error={isError}>
      <FormLabel component="legend">{label}</FormLabel>
      <Field
        component={RadioGroup}
        name={props.name}
        value={selectedValue || ''}
        onChange={handleChange}
      >
        {options.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item.value}
            control={<Radio />}
            label={item.label}
          />
        ))}
        {other.active ? (
          <div>
            <FormControlLabel
              value="outro"
              control={<Radio />}
              label="Outro:"
            />
            <OtherField formField={other} disabled={enableText} />
          </div>
        ) : null}
      </Field>
      {_renderHelperText()}
    </FormControl>
  );
}

RadioField.defaultProps = {
  options: [],
};

RadioField.propTypes = {
  options: PropTypes.array.isRequired,
};
