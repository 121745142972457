import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { FormControl, MenuItem } from '@material-ui/core';

function SelectField(props) {
  const { options } = props;
  const [field, meta] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  return (
    <FormControl fullWidth={props.fullWidth} error={isError}>
      <Field
        {...field}
        type={props.type}
        id={props.name}
        name={props.name}
        label={props.label}
        helperText={props.helperText}
        variant={props.variant}
        margin={props.margin}
        fullWidth={props.fullWidth}
        select={props.select}
        component={TextField}
      >
        {options.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
}

SelectField.defaultProps = {
  options: [],
};

SelectField.propTypes = {
  options: PropTypes.array.isRequired,
};

export default SelectField;
