import React from 'react';
import { useField, Field } from 'formik';
import { TextField } from 'formik-material-ui';

export default function InputField(props) {
  const { errorText, ...rest } = props;
  const [field, meta] = useField(props);

  return (
    <Field
      id={props.id}
      name={props.name}
      type={props.type}
      label={props.label}
      helperText={props.helperText}
      variant={props.variant}
      margin={props.margin}
      format={props.format}
      multiline={props.multiline}
      fullWidth={props.fullWidth}
      select={props.select}
      InputLabelProps={{
        shrink: true,
      }}
      component={TextField}
      {...field}
      {...rest}
      error={meta.touched && meta.error && true}
    />
  );
}
