import React from 'react';
import { useSelector } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import IconButton from '@material-ui/core/IconButton';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import Box from '@material-ui/core/Box';
import Carousel from '../../components/Carousel';

const TitleContainer = withStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
  },
}))(Container);

const CarouselContainer = withStyles(theme => ({
  root: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: 0,
    paddingRight: 0,
  },
}))(Container);

const TextContainer = withStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
}))(Container);

const ContentContainer = withStyles(() => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}))(Container);

const useStyles = makeStyles(() => ({
  contact: {
    alignItems: 'center',
    marginBottom: 0,
  },
}));

export default function About() {
  const classes = useStyles();
  const labState = useSelector(state => state.lab.data);

  return (
    <>
      {labState.about && (
        <ContentContainer maxWidth="lg">
          <CarouselContainer>
            <Carousel items={labState.about.bannerItems} />
          </CarouselContainer>
          <Box p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <TitleContainer>
                  <Typography
                    gutterBottom
                    variant="h6"
                    color="textSecondary"
                    component="h1"
                  >
                    {labState.about.fullName}
                  </Typography>
                </TitleContainer>
                <Divider />
                <TextContainer>
                  {labState.about.details.split('\n').map(paragraph => (
                    /** Se existir parágrafo, retorne o parágrafo e use como índice */
                    <Typography key={paragraph} paragraph align="justify">
                      {paragraph}
                    </Typography>
                  ))}
                </TextContainer>
              </Grid>
              <Grid item xs={12} md={4}>
                <TitleContainer>
                  <Typography
                    gutterBottom
                    variant="h6"
                    color="textSecondary"
                    component="h1"
                  >
                    Contatos
                  </Typography>
                </TitleContainer>
                <Divider />
                <TextContainer>
                  <Typography paragraph className={classes.contact}>
                    <IconButton>
                      <EmailIcon />
                    </IconButton>
                    {labState.about.email}
                  </Typography>
                  <Typography paragraph className={classes.contact}>
                    <IconButton>
                      <PhoneIcon />
                    </IconButton>
                    {labState.about.phone}
                  </Typography>
                  <Typography paragraph className={classes.contact}>
                    <IconButton>
                      <LocationOnIcon />
                    </IconButton>
                    {labState.about.address}
                  </Typography>
                </TextContainer>
              </Grid>
            </Grid>
          </Box>
        </ContentContainer>
      )}
    </>
  );
}
