import React from 'react';
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const theme = createMuiTheme({
  overrides: {
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: undefined,
        },
      },
    },
  },
});

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
  },
  media: {
    height: 140,
    flex: '0 0 auto',
  },
  content: {
    overflow: 'hidden',
  },
  button: {
    flexGrow: 1,
    whiteSpace: 'no-wrap',
  },
});

export default function MediaCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={props.onClick}>
        <CardMedia
          className={classes.media}
          image={props.image}
          title={props.title}
        />

        <MuiThemeProvider theme={theme}>
          <CardContent className={classes.content}>
            <Typography
              color="textSecondary"
              align="center"
              variant="h5"
              component="h2"
            >
              {props.title}
            </Typography>
          </CardContent>
        </MuiThemeProvider>
      </CardActionArea>
    </Card>
  );
}
