import './config/ReactotronConfig';
import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { grey, amber, red, green } from '@material-ui/core/colors';
import Routes from './routes';
import history from './services/history';
import { store, persistor } from './store';
import 'react-toastify/dist/ReactToastify.css';

export const theme = createMuiTheme({
  palette: {
    white: '#fff',
    peerlab: {
      primary: grey[300],
    },
    primary: {
      main: grey[900],
      medium: grey[200],
      light: grey[50],
      dark: grey[700],
    },
    secondary: {
      main: green[400],
      light: green[300],
      dark: green[700],
    },
    error: {
      main: red[300],
      light: red[200],
      dark: red[400],
    },
    success: {
      main: green[400],
      light: green[300],
      dark: green[700],
    },
    warn: {
      main: amber[300],
      light: amber[200],
      dark: amber[400],
    },
    lab: {
      primary: {
        main: '#244577',
        light: '#3E608E',
        dark: grey[50],
      },
      secondary: {
        main: '#F4D75D',
        light: '#F4D75D',
        dark: '#F4D75D',
      },
    },
    type: 'light',
  },
  stepper: {
    iconColor: 'green', // or logic to change color
  },
});

function App() {
  return (
    <React.StrictMode>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Router history={history}>
              <Routes />
              <ToastContainer autoClose={3000} />
            </Router>
          </PersistGate>
        </Provider>
      </MuiThemeProvider>
    </React.StrictMode>
  );
}

export default App;
