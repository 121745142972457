import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { grey } from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    borderRadius: 0,
    /** Renderiza raios na borda quando banner deixa de ser fullscreen */
    '@media screen and (min-width: 1280px)': {
      borderRadius: '0 0 10px 10px',
    },
  },
  banner: {
    height: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  box: {
    '@media screen and (max-width: 960px)': {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  typography: {
    color: grey[50],
  },
  cardContent: {
    padding: theme.spacing(3),
    backgroundColor: fade(grey[900], 0.7),
    borderRadius: '10px 10px 0 0',
    '@media screen and (max-width: 960px)': {
      borderRadius: 0,
    },
  },
}));

export default function MediaCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={0}>
      <CardMedia
        classes={{ root: classes.banner }}
        image={props.item.image}
        title={props.item.name}
      >
        <Box className={classes.box}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <CardContent classes={{ root: classes.cardContent }}>
                <Typography
                  className={classes.typography}
                  gutterBottom
                  variant="h4"
                  component="h2"
                >
                  {props.item.name}
                </Typography>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  component="p"
                  className={classes.typography}
                >
                  {props.item.description}
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Box>
      </CardMedia>
    </Card>
  );
}
