import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { services } from '../../store';
import MediaCard from './MediaCard';

// const SHEET_ID = '1buo5iig7HVJT75IUaZgbQFi5YF_0vRFkmBhKfhFhsVo';

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default function Services({ labId }) {
  const classes = useStyles();
  const [state, setState] = useState([]);
  const THIS_LAB_ID = labId;

  const fetchDataAsync = useCallback(async () => {
    // const result = await drive({
    //   sheet: SHEET_ID,
    //   tab: '2',
    //   cache: 0,
    // });

    const result = services;
    /** FILTRA DADOS COM BASE NO ID DO LABORATÓRIO ----- TEMPORÁRIO */
    const filteredTable = result.filter(
      row => parseInt(row.labid, 10) === THIS_LAB_ID
    );
    setState(filteredTable);
  }, [THIS_LAB_ID]);

  useEffect(() => {
    fetchDataAsync();
  }, [fetchDataAsync]);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        {state.map(item => (
          <Grid key={item.title} item xs={12} sm={6} md={4} lg={3}>
            <MediaCard
              title={item.title}
              description={item.description}
              image={item.imageurl}
              url="https://forms.gle/LfpLyS6PP3JSWaQC6"
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
