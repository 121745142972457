export function createEquipmentRequest(body) {
  return {
    type: '@equipment/CREATE_EQUIPMENT_REQUEST',
    payload: body,
  };
}

export function createEquipmentSuccess(data) {
  return {
    type: '@equipment/CREATE_EQUIPMENT_SUCCESS',
    payload: { data },
  };
}

export function createEquipmentFailure() {
  return {
    type: '@equipment/CREATE_EQUIPMENT_FAILURE',
  };
}

export function getCurrentEquipmentDataRequest(data) {
  return {
    type: '@equipment/GET_CURRENT_EQUIPMENT_DATA_REQUEST',
    payload: { data },
  };
}

export function getCurrentEquipmentDataSuccess(data) {
  return {
    type: '@equipment/GET_CURRENT_EQUIPMENT_DATA_SUCCESS',
    payload: { data },
  };
}

export function getCurrentEquipmentDataFailure() {
  return {
    type: '@equipment/GET_CURRENT_EQUIPMENT_DATA_FAILURE',
  };
}

export function updateEquipmentRequest(data) {
  return {
    type: '@equipment/UPDATE_EQUIPMENT_REQUEST',
    payload: { data },
  };
}

export function updateEquipmentSuccess(data) {
  return {
    type: '@equipment/UPDATE_EQUIPMENT_SUCCESS',
    payload: { data },
  };
}

export function updateEquipmentFailure() {
  return {
    type: '@equipment/UPDATE_EQUIPMENT_FAILURE',
  };
}
