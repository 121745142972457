import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Route from './Route';

import Home from '../pages/Home';
import SignUp from '../pages/SignUp';
import SignIn from '../pages/SignIn';
import Profile from '../pages/UserProfile';
import EquipmentForm from '../pages/CreateEquipment';
import Lab from '../pages/Lab';
import LabsList from '../pages/LabsList';
import NewOrder from '../pages/NewOrder';
import NewOrder2 from '../pages/NewOrder2';
import NewOrder3 from '../pages/NewOrder3';
import NewQuote from '../pages/NewQuote';

// import Profile from '../pages/Profile';
import UserDashboard from '~/pages/UserDashboard';
import Feedback from '~/pages/Feedback';
import NewEquipment from '~/pages/NewEquipment';

export default function Routes() {
  return (
    <Switch>
      {/** Public only */}
      <Route exact path="/" isPublicOnly component={Home} />

      {/** Public routes */}
      <Route exact path="/solicitar-servico" component={NewOrder} />
      <Route exact path="/solicitar-servico2" component={NewOrder2} />
      <Route exact path="/solicitar-servico3" component={NewOrder3} />
      <Route exact path="/labs" component={LabsList} />
      <Route exact path="/labs/:labInitials" component={Lab} />
      <Route exact path="/feedback" component={Feedback} isPrivate />
      {/** Sign routes */}
      <Route exact path="/signin" component={SignIn} isSign />
      <Route exact path="/signup" component={SignUp} isSign />
      {/** Private routes */}
      <Route exact path="/dashboard" component={UserDashboard} isPrivate />
      <Route exact path="/profile" component={Profile} isPrivate />
      <Route
        exact
        path="/create-equipment"
        component={EquipmentForm}
        isPrivate
      />
      <Route exact path="/orcamento" component={NewQuote} isPrivate />
      <Route exact path="/new-equipment" component={NewEquipment} isPrivate />
      <Redirect to="/" />
    </Switch>
  );
}
