import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import {
  createEquipmentSuccess,
  createEquipmentFailure,
  getCurrentEquipmentDataSuccess,
  getCurrentEquipmentDataFailure,
  updateEquipmentSuccess,
  updateEquipmentFailure,
} from './actions';

export function* createEquipment({ payload }) {
  try {
    const response = yield call(api.post, '/equipment', payload);

    toast.success('Cadastro realizado com sucesso!');

    yield put(createEquipmentSuccess(response.data));
  } catch (error) {
    toast.error(`${error.response.data.error}`);
    yield put(createEquipmentFailure());
  }
}

export function* getCurrentEquipmentData({ payload }) {
  try {
    /** Salva equipment_id de dentro do payload da ação */
    const { equipment_id } = payload.data;

    /** Faz chamada à api */
    const response = yield call(api.get, `/equipment/${equipment_id}`);

    /** ? Chama ação de sucesso passando como payload os dados buscados da api ? */
    yield put(getCurrentEquipmentDataSuccess(response.data));

    /** Caso a api retorne erro */
  } catch (error) {
    /** Notifica usuario de que houve um erro na requisicao e retorna mensagem do backend */
    toast.error(`${error.response.data.error}`);

    /** ? Chama acao de falha ? */
    yield put(getCurrentEquipmentDataFailure());
  }
}

export function* updateEquipment({ payload }) {
  try {
    /** Salva equipment_id de dentro do payload da ação */
    const body = payload.data;

    /** Faz chamada à api */
    const response = yield call(api.put, '/equipment', body);

    /** Envia feedback ao usuário */
    toast.success('Atualização realizada com sucesso!');

    /** ? Chama ação de sucesso passando como payload os dados buscados da api ? */
    yield put(updateEquipmentSuccess(response.data));

    /** Caso a api retorne erro */
  } catch (error) {
    /** Notifica usuario de que houve um erro na requisicao e retorna mensagem do backend */
    toast.error(`${error.response.data.error}`);

    /** ? Chama acao de falha ? */
    yield put(updateEquipmentFailure());
  }
}

export default all([
  takeLatest('@equipment/CREATE_EQUIPMENT_REQUEST', createEquipment),
  takeLatest(
    '@equipment/GET_CURRENT_EQUIPMENT_DATA_REQUEST',
    getCurrentEquipmentData
  ),
  takeLatest('@equipment/UPDATE_EQUIPMENT_REQUEST', updateEquipment),
]);
