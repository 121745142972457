import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Paper, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Copyright from '~/components/Copyright';

import history from '~/services/history';
import { setLabState } from '~/store/modules/lab/actions';

import LabCard from './components/LabCard';
import Title from './components/Title';

// import { Container } from './styles';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4, 6),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

function UserDashboard() {
  const classes = useStyles();
  const profile = useSelector(state => state.user.profile);

  function createData(id, title, mediaImage, access, route) {
    return { id, title, mediaImage, access, route };
  }

  const assets = [
    createData(
      0,
      'LMP',
      'https://trello-attachments.s3.amazonaws.com/5df3a9d74458ba69cb17da40/5f5d355ffd004c70c316531d/f23d6f94b29efb58dc703c4610ebff72/lmp.svg',

      /** Define se usuário tem acesso aos dados desse laboratório */
      profile.lab_id === 2, // CONDIÇÃO TEMPORÁRIA S38
      'lmp'
    ),
    createData(
      1,
      'CERMAT',
      'https://trello-attachments.s3.amazonaws.com/5f5d355ffd004c70c316531d/453x341/a8f5fed3ccef06e44c7826304b501c50/image.png',
      /** Define se usuário tem acesso aos dados desse laboratório */
      profile.lab_id === 3, // CONDIÇÃO TEMPORÁRIA S38
      'cermat'
    ),
  ];

  /** Define page name to change appbar title */
  const labName = 'PEERLAB';

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLabState({ labName }));
  }, [dispatch]);

  /** Pass argument to callback function */
  const handleRedirect = route => () => {
    history.push(route);
  };

  return (
    <Paper className={classes.paper} elevation={0}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Title>Meus laboratórios</Title>
            <Grid container spacing={2}>
              {assets.map(asset => {
                if (asset.access === true) {
                  return (
                    <Grid item key={asset.id} xs={12} sm={4} md={3} lg={2}>
                      <LabCard
                        title={asset.title}
                        image={asset.mediaImage}
                        onClick={handleRedirect(asset.route)}
                      />
                    </Grid>
                  );
                }
                return null;
              })}
            </Grid>
          </Grid>
          {/* Chart */}
          <Grid item xs={12}>
            <Title>Outros laboratórios</Title>
            <Grid container spacing={2}>
              {assets.map(asset => {
                if (asset.access === false) {
                  return (
                    <Grid item key={asset.id} xs={12} sm={4} md={3} lg={2}>
                      <LabCard
                        title={asset.title}
                        image={asset.mediaImage}
                        onClick={handleRedirect(asset.route)}
                      />
                    </Grid>
                  );
                }
                return null;
              })}
            </Grid>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </Paper>
  );
}

export default UserDashboard;
