import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  CircularProgress,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Formik, Form } from 'formik';
import { useStyles } from './styles';
import CheckoutSuccess from './CheckoutSuccess';
import formModel from './FormModel/model';
import formValidationSchema from './FormModel/validationSchema';
import formInitialValues from './FormModel/initialValues';
import FormContent from './FormContent';
import { QontoConnector, QontoStepIcon } from './CustomConnectors';
import { updateProfileRequest } from '~/store/modules/user/actions';
import api from '~/services/api';

const { formId, formSteps } = formModel;

export default function StepperForm() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === formSteps.length - 1;
  const currentValidationSchema = formValidationSchema[activeStep];
  const [userData, setUserData] = useState(null);
  const [initialValues, setInitialValues] = useState();
  const dispatch = useDispatch();

  const fetchDataAsync = async () => {
    const response = await api.get('/users');
    setUserData(response.data);
  };

  useEffect(() => {
    fetchDataAsync();
  }, []);

  /** Definie initial values based on user Data and modelInitial values */
  const handleInitialValues = (modelInitialValues, userInicialValues) => {
    const initialValuesObject = {};
    /** For each model key */
    Object.keys(modelInitialValues).forEach(key => {
      /** If key not undefined (Object.keys return array of strings) */
      if (key !== 'undefined') {
        /** If user has value for property */
        if (userInicialValues[key]) {
          /** Uses user current value */
          initialValuesObject[key] = userInicialValues[key];
        } else {
          /** User model value ('') */
          initialValuesObject[key] = modelInitialValues[key];
        }
      }
    });

    return initialValuesObject;
  };

  useEffect(() => {
    if (userData) {
      setInitialValues(handleInitialValues(formInitialValues, userData));
    }
  }, [userData]);

  /** Treat values before submitting */
  const handleValues = formValues => {
    /** Define empty object */
    const obj = {};

    /** Set null to empty fields */
    Object.keys(formValues).forEach(key => {
      let value;
      if (formValues[key] === '') {
        value = null;
      } else {
        value = formValues[key];
      }

      /** Build object */
      obj[key] = value;
    });

    /** Treat otherCourses */
    if (obj.otherCourse !== null) {
      obj.course = obj.otherCourse;
    }

    /** Treat otherScholarship */
    if (obj.otherScholarship !== null) {
      obj.scholarship = obj.otherScholarship;
    }

    /** Delete other fields */
    delete obj.otherCourse;
    delete obj.otherScholarship;

    /** Return object */
    return obj;
  };

  async function submitForm(values, actions) {
    /** Define request body */
    const body = handleValues(values);

    /** Request profile update passing body */
    dispatch(updateProfileRequest(body));

    actions.setSubmitting(false);

    /** Go to next page */
    setActiveStep(activeStep + 1);
  }

  function handleSubmit(values, actions) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <>
      {userData && (
        <>
          <Stepper
            alternativeLabel
            className={classes.stepper}
            activeStep={activeStep}
            connector={<QontoConnector />}
          >
            {formSteps.map(step => (
              <Step key={step.title}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  {step.title}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <>
            {activeStep === formSteps.length ? (
              <CheckoutSuccess />
            ) : (
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={currentValidationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={ptLocale}
                  >
                    <Form id={formId}>
                      {/** Render custom FormContent */}
                      <FormContent step={activeStep} />

                      {/** Render buttons */}
                      <div className={classes.buttons}>
                        <div className={classes.wrapper}>
                          {activeStep !== 0 && (
                            <Button
                              onClick={handleBack}
                              className={classes.button}
                            >
                              Voltar
                            </Button>
                          )}
                          <Button
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                            className={classes.buttonNext}
                            startIcon={
                              isSubmitting ? (
                                <CircularProgress size="1rem" />
                              ) : null
                            }
                          >
                            {isLastStep ? 'Enviar' : 'Próximo'}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </MuiPickersUtilsProvider>
                )}
              </Formik>
            )}
          </>
        </>
      )}
    </>
  );
}
