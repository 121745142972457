import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Accordion from './Accordion';
import Demands from './Demands';

const SHEET_ID = '1xFDoeaA3Jc6C6NZX5-z2oEgTGbRCgeiJx_cdNEuUqwQ';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default function DenseTable({ labId }) {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Accordion>
        <Demands labId={labId} sheetId={SHEET_ID} isActive isMarket />
        <Demands labId={labId} sheetId={SHEET_ID} isActive isMarket={false} />
        <Demands labId={labId} sheetId={SHEET_ID} isActive={false} />
      </Accordion>
    </Container>
  );
}
