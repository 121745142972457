import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Container } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Copyright from '~/components/Copyright';
import { setLabState } from '~/store/modules/lab/actions';
import api from '~/services/api';
import CustomForm from './CustomForm';
import ImageUploader from './ImageUploader';

export default function Profile() {
  const [userData, setUserData] = useState(null);

  /** Define page name to change appbar title */
  const name = 'PEERLAB';
  const palette = {
    primary: useTheme().palette.primary.light,
    secondary: useTheme().palette.primary.light,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLabState({ name, palette }));
  }, [dispatch, palette]);

  const fetchDataAsync = async () => {
    const response = await api.get('/users');
    setUserData(response.data);
  };

  useEffect(() => {
    fetchDataAsync();
  }, []);

  return (
    <>
      {userData && (
        <Container component="main" maxWidth="sm">
          <ImageUploader postRoute="/files" />
          <CustomForm />
          <Box mt={5}>
            <Copyright />
          </Box>
        </Container>
      )}
    </>
  );
}
