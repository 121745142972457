import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import drive from 'drive-db';
import Button from '@material-ui/core/Button';
import FileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Box from '@material-ui/core/Box';
import StyledButton from './StyledButton';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: 'organization',
    numeric: false,
    disablePadding: false,
    label: 'Organização',
  },
  { id: 'title', numeric: false, disablePadding: false, label: 'Título' },
  {
    id: 'demandername',
    numeric: false,
    disablePadding: false,
    label: 'Solicitante',
  },
  {
    id: 'datecreated',
    numeric: true,
    disablePadding: false,
    label: 'Início',
  },
  {
    id: 'datenextaction',
    numeric: true,
    disablePadding: false,
    label: 'Prazo',
  },
  {
    id: 'currentstatus',
    numeric: false,
    disablePadding: false,
    label: 'Etapa',
  },
  {
    id: 'currentstate',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  { id: 'cardurl', numeric: true, disablePadding: false, label: 'Histórico' },
  {
    id: 'driveurl',
    numeric: true,
    disablePadding: false,
    label: 'Documentos',
  },
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: 'Código',
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selecionadas
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {props.isActive === true ? 'Ativas' : 'Inativas'}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  demandCode: {
    whiteSpace: 'nowrap',
  },

  demandStatus: {
    whiteSpace: 'nowrap',
  },

  tableFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  formControl: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
  },
}));

export default function EnhancedTable({ sheetId, isActive, isMarket, labId }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const THIS_LAB_ID = labId;

  const fetchDataAsync = React.useCallback(
    async (SHEET_ID, active, market) => {
      const result = await drive({
        sheet: SHEET_ID,
        tab: '2',
        cache: 0,
      });

      let filteredTable = [];

      switch (active) {
        case true:
          filteredTable = result.filter(
            demand =>
              demand.currentstate !== 'CANCELADA' &&
              demand.currentstate !== 'CONCLUÍDA' &&
              parseInt(demand.labid, 10) === THIS_LAB_ID
          );
          switch (market) {
            case true:
              filteredTable = filteredTable.filter(
                demand =>
                  demand.sector !== 'Academia' &&
                  parseInt(demand.labid, 10) === THIS_LAB_ID
              );
              break;
            default:
              filteredTable = filteredTable.filter(
                demand =>
                  demand.sector === 'Academia' &&
                  parseInt(demand.labid, 10) === THIS_LAB_ID
              );
              break;
          }
          break;
        default:
          filteredTable = result.filter(
            demand =>
              (demand.currentstate === 'CANCELADA' ||
                demand.currentstate === 'CONCLUÍDA') &&
              parseInt(demand.labid, 10) === THIS_LAB_ID
          );
          break;
      }

      setData(filteredTable);
    },
    [THIS_LAB_ID]
  );

  React.useEffect(() => {
    fetchDataAsync(sheetId, isActive, isMarket);
  }, [fetchDataAsync, isActive, isMarket, sheetId]);

  React.useEffect(() => setRowsPerPage(data.length), [data.length]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = data.map(n => n.code);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, code) => {
    const selectedIndex = selected.indexOf(code);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, code);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const isSelected = code => selected.indexOf(code) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          isActive={isActive}
          numSelected={selected.length}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.code);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.code}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={event => handleClick(event, row.code)}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell align="left">{row.organization}</TableCell>
                      <TableCell
                        className={classes.demandDescription}
                        align="left"
                      >
                        {row.shortdescription}
                      </TableCell>
                      <TableCell align="left">{row.fullname}</TableCell>
                      <TableCell align="left">{row.datecreated}</TableCell>
                      <TableCell align="left">{row.nextdeadline}</TableCell>
                      <TableCell align="left">{row.currentstatus}</TableCell>
                      {/* <TableCell align="right">{row.estimatedvalue}</TableCell> */}
                      <TableCell className={classes.demandStatus} align="left">
                        <StyledButton
                          href={row.cardurl}
                          currentState={row.currentstate}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          size="small"
                          // color="primary"
                          className={classes.margin}
                          href={row.cardurl}
                          target="_blank"
                          fullWidth
                        >
                          <AccessTimeIcon />
                        </Button>
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          size="small"
                          // color="primary"
                          className={classes.margin}
                          href={row.driveurl}
                          target="_blank"
                          fullWidth
                        >
                          <FileIcon />
                        </Button>
                      </TableCell>
                      <TableCell
                        className={classes.demandCode}
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        {row.code}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box className={classes.tableFooter}>
          <Box className={classes.formControl}>
            <Box display="none">
              <FormControlLabel
                display="none"
                control={
                  <Switch checked={dense} onChange={handleChangeDense} />
                }
                label="Compacto"
              />
            </Box>
          </Box>

          <TablePagination
            rowsPerPageOptions={[
              0,
              5,
              10,
              25,
              { label: 'All', value: data.length },
            ]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
    </div>
  );
}
