import React from 'react';
import { Container } from './styles';

export default function NewEquipment() {
  return (
    <main>
      <Container>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeTzG_uD58NmsFeoQsfmLS5vG9ilEvk9WwZp4LJlGs84qG7OQ/viewform?embedded=true"
          width="640"
          height="1968"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="form"
          id="form"
        >
          Loading…
        </iframe>
      </Container>
    </main>
  );
}
