export default {
  formId: 'checkoutForm',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'Nome*',
      requiredErrorMsg: 'O nome é obrigatório',
    },

    email: {
      name: 'email',
      label: 'Email*',
      requiredErrorMsg: 'O email é obrigatório',
      invalidErrorMsg: 'Informe um endereço de email válido',
    },
    phone: {
      name: 'phone',
      label: 'Telefone',
      requiredErrorMsg: 'O telefone é obrigatório',
      invalidErrorMsg: 'Informe um número de telefone válido',
    },
    org: {
      name: 'org',
      label: 'Organização',
    },
    service: {
      name: 'service',
      label: 'Tipo de serviço*',
      requiredErrorMsg: 'Selecione o tipo serviço desejado',
    },
    otherService: {
      name: 'otherService',
      label: '',
    },
    description: {
      name: 'description',
      label: 'Descrição breve do serviço*',
      requiredErrorMsg: 'Forneça uma descrição do serviço desejado',
    },
    sector: {
      name: 'sector',
      label: 'Setor*',
      requiredErrorMsg: 'Selecione o setor de origem da demanda',
    },
    city: {
      name: 'city',
      label: 'Cidade*',
      requiredErrorMsg: 'Selecione a cidade',
    },
    knew: {
      name: 'knew',
      label: 'Como soube do Laboratório?*',
      requiredErrorMsg: 'Selecione uma das opções',
    },
    otherKnew: {
      name: 'otherKnew',
    },
  },
};
