import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  label: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
  avatar: {
    width: 120,
    height: 120,
  },

  input: {
    display: 'none',
  },
}));
