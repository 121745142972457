import React from 'react';
// import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
/**
 * Remove comment to use formik checkbox
 * import {CheckboxWithLabel} from 'formik-material-ui'
 */
import {
  Button,
  CircularProgress,
  Typography,
  Grid,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CssBaseline from '@material-ui/core/CssBaseline';
import Copyright from '~/components/Copyright';
import { signInRequest } from '~/store/modules/auth/actions';
import Message from '../../components/LoadingMessage';

/**
 * SIMULATE ASCINCRONOUS FUNCTION
 * const sleep = time => new Promise(acc => setTimeout(acc, time));
 */

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.light,
  },
}));

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email inválido')
    .required('Informe seu email'),
  password: Yup.string().required('Informe sua senha'),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const loading = useSelector(state => state.auth.loading);
  // const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleSubmit({ email, password }) {
    /** Define submiting state equals to true */
    // setIsSubmitting(true);

    /**
     * IMPORTANT!!!!
     * Dispara action signInRequest
     */
    dispatch(signInRequest(email, password));

    /** Return to normal state */
    // setIsSubmitting(false);

    /** EXAMPLE CODE
     * setIsSubmitting(true);
     * await sleep(1000);
     * console.tron.log({ email, password });
     * setIsSubmitting(false);
     */
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Faça o login
        </Typography>
        <Formik
          initialValues={{
            email: '',
            password: '',
            remember: false,
          }}
          validationSchema={SignInSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Field
              id="email"
              name="email"
              label="Digite seu e-mail"
              autoComplete="email"
              variant="outlined"
              margin="normal"
              fullWidth
              component={TextField}
            />

            <Field
              type="password"
              name="password"
              id="password"
              label="Digite sua senha"
              autoComplete="current-password"
              variant="outlined"
              margin="normal"
              fullWidth
              component={TextField}
            />
            {/**
              <Field
              name="remember"
              type="checkbox"
              component={CheckboxWithLabel}
              Label={{ label: 'Me mantenha conectado' }}
            />

             */}
            <Message />
            <Button
              startIcon={loading ? <CircularProgress size="1rem" /> : null}
              disabled={loading}
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              {loading ? 'Entrando' : 'Entrar'}
            </Button>

            <Grid container justify="center">
              {/**
               *
               * Descomentar bloco quando tivermos funcionalidade de resgate de senha
               *
               * <Grid item xs>
               *  <Link href="/" variant="body2">
               *    Esqueceu sua senha?
               *  </Link>
               * </Grid>
               *
               */}

              {/** REMOVER COMENTARIO PARA ATIVAR SIGNUP
              <Grid item>
                <Link href="/signup" variant="body2">
                  Criar conta
                </Link>
              </Grid>
              */}
            </Grid>
          </Form>
        </Formik>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
