import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, RadioField } from '../../FormFields';

const services = [
  {
    value: '1',
    label: 'Serviços de consultoria e treinamentos',
  },
  {
    value: '2',
    label: 'Projetos de componentes e equipamentos especiais',
  },
  {
    value: '3',
    label: 'Fabricação de componentes e ensaios de fabricação',
  },
  {
    value: '4',
    label: 'Ensaios, certificação e análise de amostras',
  },
];

const sectors = [
  {
    value: '1',
    label: 'Academia',
  },
  {
    value: '2',
    label: 'Mercado',
  },
  {
    value: '3',
    label: 'Edital',
  },
];

export default function DescriptionForm(props) {
  const {
    formField: { service, otherService, description, sector },
  } = props;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            component="body1"
            variant="body2"
            /* className={classes.text} */
          >
            Ao preencher esse formulário você estará enviando uma solicitação de
            prestação de serviço que será avaliada e possivelmente enviada a um
            laboratório universitário.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="body1"
            variant="body2" /* className={classes.text} */
          >
            A sua solicitação será avaliada e redirecionada ao laboratório
            adequado e este irá preparar um orçamento. Esse orçamento será
            enviado a você em forma de proposta dentro de alguns dias.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="body1"
            variant="body2" /* className={classes.text} */
          >
            Assim que você enviar o formulário você irá receber um email com a
            confirmação de que a solicitação foi enviada com sucesso.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            component="body1"
            variant="body2" /* className={classes.text} */
          >
            Campos obrigatórios estão marcados com asterisco*.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Descrição da solicitação:</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <RadioField
            name={sector.name}
            label={sector.label}
            data={sectors}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <RadioField
            name={service.name}
            label={service.label}
            data={services}
            other
            otherName={otherService.name}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <InputField
            multiline
            name={description.name}
            label={description.label}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}
