import React from 'react';
import { useField, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import api from '~/services/api';

const useStyles = makeStyles(theme => ({
  inputContainer: {
    position: 'relative',
    width: 186,
    height: 186,
    margin: 'auto',
  },
  label: {
    cursor: 'pointer',
  },
  avatar: {
    width: 186,
    height: 186,
    margin: 'auto',
  },
  avatarButton: {
    width: 48,
    height: 48,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  avatarButtonIcon: {
    color: theme.palette.primary.light,
  },
  input: {
    display: 'none',
  },
}));

export default function InputField(props) {
  // const { errorText, ...rest } = props;
  const classes = useStyles();
  const [field, meta] = useField(props);
  const [preview, setPreview] = React.useState(undefined);
  const [imgValid, setImgValid] = React.useState(false);

  /** Function that checks if image source is valid */
  function checkImage(imageSrc, good, bad) {
    const img = new Image();
    img.onload = good;
    img.onerror = bad;
    img.src = imageSrc;
  }

  /** Hook to call function and check validity of image */
  React.useEffect(() => {
    checkImage(
      preview,
      function() {
        setImgValid(true);
      },
      function() {
        setImgValid(false);
        setPreview(
          'https://trello-attachments.s3.amazonaws.com/5f69270e6958b76962f7db1e/400x400/266ff5366907b2ef2e5410ebccb560cc/profile.jpg'
        );
      }
    );
  }, [preview]);

  const handleChange = formProps => async event => {
    const data = new FormData();

    /** Adiciona arquivo à requisição (multipart form data) */
    data.append('file', event.target.files[0]);

    /** Envia requisição para rota files */
    const response = await api.post('files', data);

    /** Salva resposta da requisição */
    const { id, url } = response.data;

    setPreview(url);

    formProps.setFieldValue(props.name, id);
  };

  return (
    <>
      {imgValid && (
        <div className={classes.inputContainer}>
          <Avatar className={classes.avatar} alt="avatar" src={preview} />
          <label htmlFor={props.id} className={classes.label}>
            <div className={classes.avatarButton}>
              <CameraAltIcon className={classes.avatarButtonIcon} />
            </div>

            <input
              className={classes.input}
              id={props.id}
              name="file"
              type="file"
              onChange={handleChange(props)}
            />
          </label>
        </div>
      )}

      <Field
        className={classes.input}
        id={props.id}
        name={props.name}
        type={props.type}
        label={props.label}
        helperText={props.helperText}
        variant={props.variant}
        margin={props.margin}
        multiline={props.multiline}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
        select={props.select}
        component={TextField}
        setFieldValue={props.setFieldValue}
        {...field}
        error={meta.touched && meta.error && true}
      />
    </>
  );
}
