import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import Copyright from '~/components/Copyright';
import { setLabState } from '~/store/modules/lab/actions';
import Tabs from './Tabs';
import { labs } from './store';

/** -------------------- TROCAR POR CHAMADA À API ----------------------------*/

function getLabId(initials) {
  /** Define estado inicial do id do laboratório */
  let labId = '';

  /** Avalia iniciais e retorna id do laboratório */
  switch (initials) {
    case 'lmp':
      labId = 2;
      break;
    case 'cermat':
      labId = 3;
      break;
    default: {
      labId = 0;
    }
  }

  return labId;
}

export default function Dashboard() {
  const [labData, setLabData] = useState({
    id: '',
    name: '',
    palette: { primary: '', secondary: '' },
    about: {
      fullName: '',
      email: '',
      phone: '',
      address: '',
      details: '',
    },
  });

  /** Iniciais do laboratorio ---- TEMPORARIO */
  const { labInitials } = useParams();

  /** Id do laboratorio */
  const labId = getLabId(labInitials);

  /** Dados do laboratório baseados no id */
  const lab = labs.find(item => item.id === labId);

  useEffect(() => {
    setLabData(lab);
  }, [lab, labId]);

  /** Define função para mudar estado global e altera estado do laboratório */
  const dispatch = useDispatch();

  /** Chama função uma vez */
  useEffect(() => {
    /** Dispara função setLabState */
    dispatch(
      setLabState({
        id: labData.id,
        name: labData.name,
        palette: labData.palette,
        about: labData.about,
      })
    );
  }, [dispatch, labData.about, labData.id, labData.name, labData.palette]);

  return (
    <main>
      {/** Redireciona usuario para /labs se a rota não corresponde ao id de nenhum laboratório */}
      {!lab && <Redirect to="/labs" />}
      {/** Chama Tabs e passa id do laboratorio para tabs como props */}
      <Tabs labId={labId} labAgentName={labInitials} />
      <Copyright />
    </main>
  );
}
