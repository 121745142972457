import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';

function ContactDetails(props) {
  const { formValues } = props;
  const classes = useStyles();
  const { firstName, email, phone, org, city } = formValues;

  return (
    <Grid item container direction="column" xs={12}>
      <Typography variant="h6" gutterBottom className={classes.title}>
        Dados pessoais:
      </Typography>
      <Grid container>
        <Grid item xs={4}>
          <Typography gutterBottom>Nome:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography gutterBottom>{firstName}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography gutterBottom>Email:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography gutterBottom>{email}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography gutterBottom>Telefone:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography gutterBottom>{phone}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography gutterBottom>Organização:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography gutterBottom>{org}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography gutterBottom>Cidade:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography gutterBottom>{city}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ContactDetails;
