import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import CustomForm from './CustomForm';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
  },
}));

export default function EquipmentForm({ labId }) {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="sm" className={classes.paper}>
      <CustomForm labId={labId} />
    </Container>
  );
}
