import React from 'react';

export default function Field({ formField, disabled, setFieldValue }) {
  const Component = formField.component;

  return (
    <Component
      name={formField.name}
      label={formField.label}
      helperText={formField.helperText}
      options={formField.options}
      type={formField.type}
      variant={formField.variant}
      inputVariant={formField.inputVariant}
      fullWidth={formField.fullWidth}
      select={formField.select}
      margin={formField.margin}
      other={formField.other}
      format={formField.format}
      disabled={disabled}
      setFieldValue={setFieldValue}
    />
  );
}
