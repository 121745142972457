import React from 'react';

import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  successButton: {
    background: theme.palette.success.light,
    '&:hover': {
      background: theme.palette.success.dark,
    },
  },
  errorButton: {
    background: theme.palette.error.light,
    '&:hover': {
      background: theme.palette.error.dark,
    },
  },
  warnButton: {
    background: theme.palette.warn.light,
    '&:hover': {
      background: theme.palette.warn.dark,
    },
  },
  canceledButton: {
    background: theme.palette.primary.light,
  },
}));

function StyledButton({ currentState, href }) {
  const classes = useStyles();

  let myClass = {};

  switch (currentState) {
    case 'ATRASADA':
      myClass = classes.errorButton;
      break;
    case 'ATENÇÃO':
      myClass = classes.warnButton;
      break;
    case 'CANCELADA':
      myClass = classes.canceledButton;
      break;
    default:
      myClass = classes.successButton;
  }

  return (
    <Button
      variant="contained"
      fullWidth
      disableElevation
      className={myClass}
      href={href}
      target="_blank"
    >
      {currentState}
    </Button>
  );
}

export default StyledButton;
