import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import TeamMembers from './TeamMembers';
import Equipment from './Equipment';
import Services from './Services';
import Demands from './Dashboard';
import About from './About';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  scroller: {
    flexGrow: '0',
  },
  customIndicator: {
    backgroundColor: theme.palette.primary.main,
  },
  customSelected: {
    color: theme.palette.primary.main,
  },
  head: {
    top: '56px',
    '@media screen and (min-width: 600px)': {
      top: '64px',
    },
  },
}));

export default function ScrollableTabsButtonAuto({ labId, labAgentName }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  /** Chave de acesso do laboratorio dono desta pagina ---- TEMPORARIO */
  const thisLabId = labId;

  /** Busca id do laboratorio do usuario */
  const userLabId = useSelector(
    state => state.user.profile && state.user.profile.lab_id
  );

  /** Confere se usuario tem acesso a informacoes privadas do laboratorio */
  const hasPrivateAccess = thisLabId === userLabId;

  /** Índice para ajuste das abas */
  const indexDiff = hasPrivateAccess ? 0 : 1;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const labState = useSelector(state => state.lab.data);

  /** If there is a palette in state... */
  const useStateStyles = labState.palette
    ? makeStyles(theme => ({
        /** ... Define complemantary style from state */
        customIndicatorStateStyle: {
          backgroundColor: labState.palette.primary,
        },
        customSelectedStateStyle: {
          color: theme.palette.primary.light,
        },
      }))
    : /** ... Else, set complementary style to empty */
      makeStyles({ appBarStateStyle: {} });

  const stateClasses = useStateStyles();

  return (
    <div className={classes.root}>
      <AppBar position="sticky" className={classes.head} color="default">
        <Tabs
          classes={{
            root: classes.root,
            scroller: classes.scroller,
            indicator: clsx(
              classes.customIndicator,
              stateClasses.customIndicatorStateStyle
            ),
          }}
          value={value}
          onChange={handleChange}
          // indicatorColor="primary"
          // textColor="primary"
          variant="scrollable"
          scrollButtons="on"
        >
          {hasPrivateAccess && (
            <Tab
              label="Dashboard"
              {...a11yProps(0)}
              classes={{
                selected: classes.customSelected,
              }}
            />
          )}
          <Tab
            label="Sobre"
            {...a11yProps(1)}
            classes={{
              selected: classes.customSelected,
            }}
          />
          <Tab
            label="Serviços"
            {...a11yProps(2)}
            classes={{
              selected: classes.customSelected,
            }}
          />
          <Tab
            label="Equipamentos"
            {...a11yProps(3)}
            classes={{
              selected: classes.customSelected,
            }}
          />
          <Tab
            label="Equipe"
            {...a11yProps(4)}
            classes={{
              selected: classes.customSelected,
            }}
          />
        </Tabs>
      </AppBar>
      {hasPrivateAccess && (
        <TabPanel value={value} index={0}>
          <Box p={3}>
            <Demands labId={labId} labAgentName={labAgentName} />
          </Box>
        </TabPanel>
      )}
      <TabPanel value={value} index={1 - indexDiff}>
        {/**
         * Page about has padding zero so that carousel is fullscreen on smartphone
         */}
        <Box p={0}>
          <About />
        </Box>
      </TabPanel>

      <TabPanel value={value} index={2 - indexDiff}>
        <Box p={3}>
          <Services labId={labId} labAgentName={labAgentName} />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={3 - indexDiff}>
        <Box p={3}>
          <Equipment labId={labId} labAgentName={labAgentName} />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={4 - indexDiff}>
        <Box p={3}>
          <TeamMembers labId={labId} labAgentName={labAgentName} />
        </Box>
      </TabPanel>
    </div>
  );
}
