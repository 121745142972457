import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { grey } from '@material-ui/core/colors';
import { useDispatch } from 'react-redux';
import { Content, Wrapper } from './styles';
import Lab from './img/Micro.png';
import { setLabState } from '~/store/modules/lab/actions';
import history from '~/services/history';

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    // backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 0),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  paragraph: {
    color: theme.palette.primary.light,
  },
  submit: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.light,
  },
  secondButton: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
}));

export default function Home() {
  const classes = useStyles();

  /** Define page name to change appbar title */
  const name = 'PEERLAB';
  const palette = {
    primary: grey[50],
    secondary: grey[50],
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLabState({ name, palette }));
  }, [dispatch, palette]);

  /** Navega usuario para pagina adequada */
  const handleNavigate = route => () => {
    /** Se usuário estiver em um iFrame... */
    if (window.self !== window.top) {
      /** Redireciona página superior para rota desejada, porém no domínio peerlab.com.br */
      window.top.location.href = `https://www.peerlab.com.br${route}`;
    } else {
      history.push(route);
    }
  };

  return (
    <>
      <CssBaseline />
      <Wrapper>
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <Content>
              <img src={Lab} alt="" />
              <div id="right-content">
                <Typography
                  color="primary"
                  variant="h4"
                  align="center"
                  paragraph
                  id="subtitle"
                >
                  Solicite serviços a laboratórios universitários
                </Typography>
                <div className={classes.heroButtons}>
                  <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                      <Button
                        className={classes.submit}
                        variant="contained"
                        size="large"
                        onClick={handleNavigate('/labs')}
                        fullWidth
                      >
                        Ver Laboratórios
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        className={classes.secondButton}
                        variant="contained"
                        size="large"
                        onClick={handleNavigate('/solicitar-servico')}
                        fullWidth
                      >
                        Solicitar serviço
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Content>
          </Container>
        </div>
      </Wrapper>
    </>
  );
}
