import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { updateProfileSuccess, updateProfilefailure } from './actions';

export function* updateProfile({ payload }) {
  try {
    const {
      name,
      surname,
      email,
      phone,
      linkedin_url,
      lattes_url,
      course,
      position,
      advisor,
      co_advisor,
      technical_leader,
      research_field,
      end_date,
      lab_id,
      scholarship,
      avatar_id,
      activities,
      equipment,
      keywords,
      languages,
      service_availability,
      total_availability,
      ...rest
    } = payload.data;

    const profile = {
      name,
      surname,
      email,
      phone,
      linkedin_url,
      lattes_url,
      course,
      position,
      advisor,
      co_advisor,
      technical_leader,
      research_field,
      end_date,
      lab_id,
      scholarship,
      avatar_id,
      activities,
      equipment,
      keywords,
      languages,
      service_availability,
      total_availability,
      ...(rest.oldPassword ? rest : {}),
    };

    const response = yield call(api.put, 'users', profile);

    toast.success('Perfil atualizado com sucesso!');

    yield put(updateProfileSuccess(response.data));
  } catch (error) {
    // console.tron.log(error);
    toast.error('Erro ao atualizar Perfil, confira seus dados');
    yield put(updateProfilefailure());
  }
}

export default all([takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile)]);
