import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';

function CheckoutSuccess() {
  /** Refresh page two seconds after loading it */
  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  });

  return (
    <>
      <Typography variant="h5" gutterBottom align="center">
        Perfil Atualizado com sucesso
      </Typography>
    </>
  );
}

export default CheckoutSuccess;
