import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  CircularProgress,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Formik, Form } from 'formik';
import { useStyles } from './styles';
import CheckoutSuccess from './CheckoutSuccess';
import formModel from './FormModel/model';
import formValidationSchema from './FormModel/validationSchema';
import formInitialValues from './FormModel/initialValues';
import FormContent from './FormContent';
import { QontoConnector, QontoStepIcon } from './CustomConnectors';
import {
  getCurrentEquipmentDataRequest,
  updateEquipmentRequest,
} from '~/store/modules/equipment/actions';

const { formId, formSteps } = formModel;

export default function StepperForm({ equipmentId }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === formSteps.length - 1;
  const currentValidationSchema = formValidationSchema[activeStep];
  const [initialValues, setInitialValues] = useState(null);
  const equipmentData = useSelector(state => state.equipment.profile);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentEquipmentDataRequest({ equipment_id: equipmentId }));
  }, [dispatch, equipmentId]);

  /** Definie initial values based on current data and modelInitial values */
  const handleInitialValues = (modelInitialValues, currentData) => {
    const initialValuesObject = {};
    /** For each model key */
    Object.keys(modelInitialValues).forEach(key => {
      /** If key not undefined (Object.keys return array of strings) */
      if (key !== 'undefined') {
        /** If user has value for property */
        if (currentData[key]) {
          /** Uses user current value */
          initialValuesObject[key] = currentData[key];
          /** If user doesn't have value for property */
        } else {
          /** Use model value (currently, an empty string '') */
          initialValuesObject[key] = modelInitialValues[key];
        }
      }
    });

    return initialValuesObject;
  };

  useEffect(() => {
    /** If equipment data is available */
    if (equipmentData) {
      /** Format initial values */
      const formattedInitialValues = handleInitialValues(
        formInitialValues,
        equipmentData
      );
      /** Set initial values state equal to formatted data */
      setInitialValues(formattedInitialValues);
    }
  }, [equipmentData]);

  /** Treat values before submitting */
  const handleValues = formValues => {
    /** Define empty object */
    const obj = {};

    /** Set null to empty fields */
    Object.keys(formValues).forEach(key => {
      let value;
      if (formValues[key] === '') {
        value = null;
      } else {
        value = formValues[key];
      }

      /** Build object */
      obj[key] = value;
    });

    return obj;
  };

  async function submitForm(values, actions) {
    /** Define request body */
    const body = handleValues(values);

    /**  Define equipment_id */
    body.equipment_id = equipmentId;

    // /** Request profile update passing body */
    dispatch(updateEquipmentRequest(body));

    actions.setSubmitting(false);

    /** Go to next page */
    setActiveStep(activeStep + 1);
  }

  function handleSubmit(values, actions) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <>
      <>
        {initialValues && (
          <>
            {activeStep === formSteps.length ? (
              <CheckoutSuccess />
            ) : (
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={currentValidationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, setFieldValue }) => (
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={ptLocale}
                  >
                    <Form id={formId}>
                      {/** Render custom FormContent */}
                      <FormContent
                        step={activeStep}
                        setFieldValue={setFieldValue}
                      />

                      {/** Render buttons */}
                      <div className={classes.buttons}>
                        <div className={classes.wrapper}>
                          {activeStep !== 0 && (
                            <Button
                              onClick={handleBack}
                              className={classes.button}
                            >
                              Voltar
                            </Button>
                          )}
                          <Button
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                            className={classes.buttonNext}
                            startIcon={
                              isSubmitting ? (
                                <CircularProgress size="1rem" />
                              ) : null
                            }
                          >
                            {isLastStep ? 'Enviar' : 'Próximo'}
                          </Button>
                        </div>
                      </div>
                      <Stepper
                        alternativeLabel
                        className={classes.stepper}
                        activeStep={activeStep}
                        connector={<QontoConnector />}
                      >
                        {formSteps.map(step => (
                          <Step key={step.title}>
                            <StepLabel StepIconComponent={QontoStepIcon}>
                              {step.title}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Form>
                  </MuiPickersUtilsProvider>
                )}
              </Formik>
            )}
          </>
        )}
      </>
    </>
  );
}
