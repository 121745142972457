import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useStyle } from './styles';
import history from '~/services/history';

export default function Copyright() {
  const classes = useStyle();

  /** Navega usuario para pagina adequada */
  const handleNavigate = route => () => {
    /** Se usuário estiver em um iFrame... */
    if (window.self !== window.top) {
      /** Redireciona página superior para rota desejada, porém no domínio peerlab.com.br */
      window.top.location.href = `https://www.peerlab.com.br${route}`;
    } else {
      history.push(route);
    }
  };

  return (
    <Box p={4}>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        onClick={handleNavigate('/')}
        className={classes.root}
      >
        {'Powered by © '}
        Peerlab {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Box>
  );
}
