import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import CardActionArea from '@material-ui/core/CardActionArea';

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: 10,
    width: '100%',
    height: 155,
    textAlign: 'center',
  },
  avatar: {
    width: 60,
    height: 60,
    margin: 'auto',
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginTop: 8,
    marginBottom: 0,
  },
  subheader: {
    fontSize: 14,
    color: palette.grey[500],
    marginBottom: '0.875em',
  },
}));

export const TeamCard = React.memo(function ProfileCard(props) {
  const styles = useStyles();
  const [raised, setRaised] = React.useState(false);

  const toggleRaised = () => setRaised(!raised);

  const handleClick = () => {
    if (props.linkedinUrl) {
      window.open(props.linkedinUrl, '_blank');
    }
  };

  return (
    <CardActionArea onClick={handleClick}>
      <Card
        className={cx(styles.card)}
        onMouseOver={toggleRaised}
        onMouseOut={toggleRaised}
        raised={raised}
      >
        <CardContent>
          <Avatar className={styles.avatar} src={props.ImgSrc} />
          <h3 className={styles.heading}>{props.name}</h3>
          <span className={styles.subheader}>{props.description}</span>
        </CardContent>
      </Card>
    </CardActionArea>
  );
});
