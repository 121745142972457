import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grey from '@material-ui/core/colors/grey';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    color: Grey[500],
  },
}));

function LoadingMessage() {
  const classes = useStyles();

  return (
    <Typography variant="body2" align="justify" className={classes.root}>
      Este sistema está em processo de desenvolvimento. Ao clicar no botão,
      aguarde para que a ação seja executada.
    </Typography>
  );
}

export default LoadingMessage;
