import React from 'react';
import FormLayout from './FormLayout';
import StepperForm from './StepperForm';

export default function FormPage() {
  return (
    <FormLayout>
      <StepperForm />
    </FormLayout>
  );
}
