import React from 'react';

// import { Container } from './styles';

function IFrame({ iFrameSource }) {
  return (
    <>
      <iframe
        title={iFrameSource}
        src={`https://${iFrameSource}.youcanbook.me/?noframe=true&skipHeaderFooter=true`}
        id={`ycbmiframe${iFrameSource}`}
        height="100%"
        style={{
          width: '100%',

          backgroundColor: 'transparent',
          flexGrow: '1 0 auto',
        }}
        seamless="seamless"
        frameBorder="0"
        allowtransparency="true"
      />
      {window.addEventListener &&
        window.addEventListener(
          'message',
          event => {
            if (event.origin === { iFrameSource }) {
              document.getElementById(
                `ycbmiframe${iFrameSource}`
              ).style.height = `${event.data}px`;
            }
          },
          false
        )}
    </>
  );
}

export default IFrame;
