import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { getLabEquipmentSuccess, getLabEquipmentFailure } from './actions';

export function* getLabEquipment({ payload }) {
  try {
    const { lab_id } = payload.data;

    const response = yield call(api.get, `/lab-equipment/${lab_id}`);

    yield put(getLabEquipmentSuccess(response.data));
  } catch (error) {
    // console.tron.log(error);
    toast.error('Erro ao listar equipamentos.');
    yield put(getLabEquipmentFailure());
  }
}

export default all([
  takeLatest('@lab/GET_LAB_EQUIPMENT_REQUEST', getLabEquipment),
]);
