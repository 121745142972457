import model from './model';

/** Get formSteps from model */
const { formSteps } = model;

/** Define validation object */
const initialValuesObject = {};

/** For each formStep */
formSteps.forEach(step => {
  const { formFields } = step;

  /** Populate validation object with (name, validation) pairs */
  formFields.forEach(field => {
    initialValuesObject[field.name] = '';

    /** If there is a 'other' field, adds field validation */
    if (field.other && field.other.name) {
      initialValuesObject[field.other.name] = '';
    }
  });
});

/** Export list of validation objects */
export default initialValuesObject;
