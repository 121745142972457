import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { fade, makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import history from '~/services/history';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.primary.light,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  labSummary: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    height: '150px',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    minWidth: drawerWidth,
    paddingTop: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  grow: {
    flexGrow: 1,
  },

  title: {
    // REMOVE COMMENTS TO ADD DISPLAY RULES
    // display: 'none',
    // [theme.breakpoints.up('sm')]: {
    //   display: 'block',
    // },
    color: theme.palette.primary.main,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  box: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  toolbar: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  drawerButton: {
    flexGrow: 0,
    color: theme.palette.peerlab.primary,
  },
}));

const firstMenuListItems = [
  {
    title: 'Início',
    icon: <HomeIcon />,
    href: '/',
  },
  {
    title: 'Laboratórios',
    icon: <BusinessIcon />,
    href: '/labs',
  },
  // {
  //   title: 'Mais serviços',
  //   icon: <BuildIcon />,
  //   href: '/lmp',
  // },
  // {
  //   title: 'Mais equipamentos',
  //   icon: <RouterIcon />,
  //   href: '/',
  // },
];

const secondMenuListItems = [
  // {
  //   title: 'Compartilhar',
  //   icon: <ShareIcon />,
  //   href: '/',
  // },
  {
    title: 'Criar demanda',
    icon: <PostAddIcon />,
    href: '/solicitar-servico',
  },
];

export default function AuthLayout({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const labState = useSelector(state => state.lab.data);

  /** If there is a palette in state... */
  const useStateStyles = labState.palette
    ? makeStyles({
        /** ... Define complemantary style from state */
        appBarStateStyle: {
          backgroundColor: labState.palette.primary,
        },
        iconsStateStyle: {
          color:
            labState.palette.primary === theme.palette.primary.light
              ? theme.palette.primary.dark
              : theme.palette.primary.light,
        },
        buttonStateStyle: {
          padding: '5px 15px',
          border: `1px solid ${
            labState.palette.primary === theme.palette.primary.light
              ? theme.palette.primary.dark
              : theme.palette.primary.light
          }`,
          '&$disabled': {
            border: `1px solid ${theme.palette.action.disabledBackground}`,
          },
        },
        buttonTextStateStyle: {
          color:
            labState.palette.primary === theme.palette.primary.light
              ? theme.palette.primary.dark
              : theme.palette.primary.light,
        },
      })
    : /** ... Else, set complementary style to empty */
      makeStyles({ appBarStateStyle: {} });

  const stateClasses = useStateStyles();

  /**
   * Define initial state based on viewport
   */
  const [open, setOpen] = React.useState(window.innerWidth < 0);

  // const [open, setOpen] = React.useState(window.innerWidth > 500);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  /** Navega usuario para pagina adequada */
  const handleNavigate = route => () => {
    /** Se usuário estiver em um iFrame... */
    if (window.self !== window.top) {
      /** Redireciona página superior para rota desejada, porém no domínio peerlab.com.br */
      window.top.location.href = `https://www.peerlab.com.br${route}`;
    } else {
      /** Caso não esteja num iFrame navega para rota usando history.push */
      history.push(route);
    }

    /** Fecha drawer caso esteja aberto */
    if (open) {
      handleDrawerClose();
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(
          classes.appBar,
          {
            [classes.appBarShift]: open,
          },
          stateClasses.appBarStateStyle
        )}
      >
        <Box className={classes.box}>
          <Container maxWidth="lg" className={classes.container}>
            <Toolbar className={classes.toolbar}>
              <IconButton
                aria-label="open drawer"
                onClick={!open ? handleDrawerOpen : handleDrawerClose}
                edge="start"
                className={clsx(
                  classes.menuButton,

                  // open && classes.hide,
                  stateClasses.iconsStateStyle
                )}
              >
                <MenuIcon />
              </IconButton>

              <Button className={classes.title} onClick={handleNavigate('/')}>
                <Typography
                  className={clsx(classes.title, stateClasses.iconsStateStyle)}
                  variant="h6"
                  noWrap
                >
                  {labState.name}
                </Typography>
              </Button>
              {/**
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
          */}
              <div className={classes.grow} />
              <Button
                classes={{
                  outlined: stateClasses.buttonStateStyle,
                  root: stateClasses.buttonTextStateStyle,
                }}
                onClick={handleNavigate('/signin')}
                variant="outlined"
              >
                Login
              </Button>
            </Toolbar>
          </Container>
        </Box>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Button
            className={classes.drawerButton}
            fullWidth
            onClick={handleNavigate('/')}
          >
            <Typography variant="h5" noWrap>
              PEERLAB
            </Typography>
          </Button>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {firstMenuListItems.map(item => (
            <ListItem
              button
              key={item.title}
              onClick={handleNavigate(item.href)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {secondMenuListItems.map(item => (
            <ListItem
              button
              key={item.title}
              onClick={handleNavigate(item.href)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
