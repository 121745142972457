import * as Yup from 'yup';
import { InputField, SelectField, FileUpload } from '../FieldModels';

export default {
  formId: 'createEquipment',
  formSteps: [
    {
      title: '',
      formFields: [
        {
          get id() {
            return this.name;
          },
          label: '',
          name: 'avatar_id',
          type: 'text',
          component: FileUpload,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Laboratório*',
          name: 'lab_id',
          type: 'text',
          component: SelectField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [
            {
              label: 'CERMAT',
              value: 3,
            },
            {
              label: 'LMP',
              value: 2,
            },
          ],
          other: {},
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Nome do equipamento*',
          name: 'equipment_name',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: 'Informe o nome do equipamento',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Categoria*',
          name: 'category',
          type: 'text',
          component: SelectField,
          helperText: '',
          requiredErrorMsg: 'Informe a categoria do equipamento',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [
            {
              value:
                'Fabricação, processamento e transformação de matéria prima',
              label:
                'Fabricação, processamento e transformação de matéria prima',
            },
            {
              value: 'Medição, análise e ensaio',
              label: 'Medição, análise e ensaio',
            },
            {
              value: 'Manuseio, transporte e movimentação',
              label: 'Manuseio, transporte e movimentação',
            },
            {
              value: 'Montagem e ajuste',
              label: 'Montagem e ajuste',
            },
          ],
          other: {},
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Descrição breve*',
          name: 'short_description',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: 'Faça uma breve descrição do uso do equipamento',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
      ],
    },
  ],
};
