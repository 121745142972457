import * as Yup from 'yup';
import { format } from 'date-fns';
import { InputField, RadioField, SelectField, DateField } from '../FieldModels';

/** Define data máxima para validação de campo de data  */
function getMaximumDate(yearsFromNow) {
  const now = new Date();

  /** Final date values */
  const maximumDateDay = now.getDate();
  const maximumDateMonth = now.getMonth(); // January equals 0
  const maximumDateYear = now.getFullYear() + yearsFromNow;

  /** Maximum date */
  const maximumDate = new Date(
    maximumDateYear,
    maximumDateMonth,
    maximumDateDay
  );

  /** Return object of values */
  return maximumDate;
}

export default {
  formId: 'checkoutForm',
  formSteps: [
    {
      title: '',
      formFields: [
        {
          get id() {
            return this.name;
          },
          label: 'Nome*',
          name: 'name',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: 'Informe seu nome',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Sobrenome*',
          name: 'surname',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: 'Informe seu sobrenome',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Email*',
          name: 'email',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: 'Informe seu email',
          invalidErrorMsg: 'Informe um endereço de email válido',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string()
              .email(this.invalidErrorMsg)
              .required(this.requiredErrorMsg);
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Telefone',
          name: 'phone',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: 'Digite um número',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.number()
              .typeError(this.invalidErrorMsg)
              .nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'URL do LinkedIn',
          name: 'linkedin_url',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: 'Informe um uma URL válida',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string()
              .url(this.invalidErrorMsg)
              .nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'URL do Lattes',
          name: 'lattes_url',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: 'Informe um uma URL válida',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string()
              .url(this.invalidErrorMsg)
              .nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
      ],
    },
    {
      title: ' ',
      formFields: [
        {
          get id() {
            return this.name;
          },
          label: 'Laboratório',
          name: 'lab_id',
          type: 'text',
          component: SelectField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [
            {
              value: '2',
              label: 'LMP',
            },
            {
              value: '3',
              label: 'CERMAT',
            },
          ],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Fim das atividades*',
          name: 'end_date',
          type: undefined,
          component: DateField,
          helperText: `Data aproximada de encerramento de suas atividades no laboratório.`,
          requiredErrorMsg: '',
          invalidErrorMsg: 'Invalid',
          variant: 'outlined',
          get inputVariant() {
            return this.variant;
          },
          fullWidth: true,
          margin: undefined,
          multiline: false,
          format: 'dd/MM/yyyy',
          options: [],
          other: {},
          get validation() {
            const maximumDate = getMaximumDate(50); // Limit: 50 years from now
            return Yup.date()
              .max(
                maximumDate,
                `Data máxima: ${format(maximumDate, 'dd/MM/yyyy')}`
              )
              .nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Posição',
          name: 'position',
          type: 'text',
          component: SelectField,
          helperText:
            'Informação necessária para aparecer na equipe do laboratório',
          requiredErrorMsg: '',
          invalidErrorMsg: 'Informe um uma URL válida',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [
            {
              value: 'Professor Coordenador',
              label: 'Professor Coordenador',
            },
            {
              value: 'Professor',
              label: 'Professor',
            },
            {
              value: 'Técnico',
              label: 'Técnico',
            },
            {
              value: 'Pós-Doutorado',
              label: 'Pós-Doutorado',
            },
            {
              value: 'Doutorado',
              label: 'Doutorado',
            },
            {
              value: 'Mestrado',
              label: 'Mestrado',
            },
            {
              value: 'Graduação',
              label: 'Graduação',
            },
          ],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Professor Orientador',
          name: 'advisor',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Professor Co-orientador',
          name: 'co_advisor',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Lider técnico',
          name: 'technical_leader',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
      ],
    },
    {
      title: '  ',
      formFields: [
        {
          get id() {
            return this.name;
          },
          label: 'Linha de pesquisa principal',
          name: 'research_field',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Palavras-chave',
          name: 'keywords',
          type: 'text',
          component: InputField,
          helperText: 'Use vírgulas para separar as palavras',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Curso de graduação',
          name: 'course',
          type: 'text',
          component: RadioField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [
            {
              value: 'Engenharia Automotiva',
              label: 'Engenharia Automotiva',
            },
            {
              value: 'Engenharia de Controle e Automação',
              label: 'Engenharia de Controle e Automação',
            },
            {
              value: 'Engenharia de Materiais',
              label: 'Engenharia de Materiais',
            },
            {
              value: 'Engenharia de Produção',
              label: 'Engenharia de Produção',
            },
            {
              value: 'Engenharia Mecânica',
              label: 'Engenharia Mecânica',
            },

            {
              value: 'Engenharia Mecatrônica',
              label: 'Engenharia Mecatrônica',
            },
          ],
          other: {
            get id() {
              return this.name;
            },
            label: '',
            name: 'otherCourse',
            type: 'text',
            active: true,
            component: InputField,
            helperText: '',
            requiredErrorMsg: '',
            invalidErrorMsg: '',
            variant: undefined,
            fullWidth: false,
            margin: undefined,
            multiline: false,
            select: false,
            get validation() {
              return Yup.string().nullable();
            },
          },
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
      ],
    },
    {
      title: '    ',
      formFields: [
        {
          get id() {
            return this.name;
          },
          label: 'Provedor',
          name: 'provider',
          type: 'text',
          component: SelectField,
          helperText:
            'Você gostaria de participar de ações de extensão em seu laboratório?',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [
            {
              value: 'true',
              label: 'Sim',
            },
            {
              value: 'false',
              label: 'Não',
            },
            {
              value: 'false',
              label: 'Ainda não sei',
            },
          ],
          other: {},
          get validation() {
            return Yup.boolean().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Equipamentos que opera',
          name: 'equipment',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Atividades que realiza',
          name: 'activities',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Idiomas',
          name: 'languages',
          type: 'text',
          component: InputField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Tempo disponível',
          name: 'service_availability',
          type: 'text',
          component: SelectField,
          helperText:
            'Tempo semanal disponível para prestação de serviços de extensão',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [
            {
              value: 'Não tenho disponibilidade',
              label: 'Não tenho disponibilidade',
            },
            {
              value: '0 a 5 horas',
              label: '0 a 5 horas',
            },
            {
              value: '5 a 10 horas',
              label: '5 a 10 horas',
            },
            {
              value: '10 a 15 horas',
              label: '10 a 15 horas',
            },
            {
              value: '15 a 20 horas',
              label: '15 a 20 horas',
            },
            {
              value: 'Mais de 20 horas',
              label: 'Mais de 20 horas',
            },
          ],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
        {
          get id() {
            return this.name;
          },
          label: 'Dedicação total',
          name: 'total_availability',
          type: 'text',
          component: SelectField,
          helperText: 'Tempo semanal dedicado a atividades do laboratório',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [
            {
              value: 'Não tenho disponibilidade',
              label: 'Não tenho disponibilidade',
            },
            {
              value: '0 a 5 horas',
              label: '0 a 5 horas',
            },
            {
              value: '5 a 10 horas',
              label: '5 a 10 horas',
            },
            {
              value: '10 a 15 horas',
              label: '10 a 15 horas',
            },
            {
              value: '15 a 20 horas',
              label: '15 a 20 horas',
            },
            {
              value: 'Mais de 20 horas',
              label: 'Mais de 20 horas',
            },
          ],
          other: {},
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
      ],
    },
    {
      title: '    ',
      formFields: [
        {
          get id() {
            return this.name;
          },
          label: 'Bolsa de estudos',
          name: 'scholarship',
          type: 'text',
          component: RadioField,
          helperText: '',
          requiredErrorMsg: '',
          invalidErrorMsg: '',
          variant: 'outlined',
          fullWidth: true,
          margin: undefined,
          multiline: false,
          options: [
            {
              value: 'Não tenho bolsa',
              label: 'Não tenho bolsa',
            },
            {
              value: 'Empresa',
              label: 'Empresa',
            },
            {
              value: 'Monitoria',
              label: 'Monitoria',
            },
            {
              value: 'CAPES',
              label: 'CAPES',
            },
            {
              value: 'CNPq',
              label: 'CNPq',
            },
            {
              value: 'PIBIC',
              label: 'PIBIC',
            },
            {
              value: 'PIBIT',
              label: 'PIBIT',
            },
            {
              value: 'SUS',
              label: 'SUS',
            },
          ],
          other: {
            get id() {
              return this.name;
            },
            label: '',
            name: 'otherScholarship',
            type: 'text',
            active: true,
            component: InputField,
            helperText: '',
            requiredErrorMsg: '',
            invalidErrorMsg: '',
            variant: undefined,
            fullWidth: false,
            margin: undefined,
            multiline: false,
            select: false,
            get validation() {
              return Yup.string().nullable();
            },
          },
          get validation() {
            return Yup.string().nullable();
          },
          get select() {
            return this.component === SelectField ? true : undefined;
          },
        },
      ],
    },
  ],
};
