import * as Yup from 'yup';
// import moment from 'moment';
import checkoutFormModel from './checkoutFormModel';

const {
  formField: {
    firstName,
    email,
    service,
    description,
    sector,
    city,
    knew,
    phone,
  },
} = checkoutFormModel;

const phoneNumber = /^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/;

export default [
  Yup.object().shape({
    [service.name]: Yup.string().required(`${service.requiredErrorMsg}`),
    [description.name]: Yup.string().required(
      `${description.requiredErrorMsg}`
    ),
    [sector.name]: Yup.string().required(`${sector.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [email.name]: Yup.string()
      .email(`${email.invalidErrorMsg}`)
      .required(`${email.requiredErrorMsg}`),
    [phone.name]: Yup.string()
      .required(`${phone.requiredErrorMsg}`)
      .matches(phoneNumber, `${phone.invalidErrorMsg}`),
    [city.name]: Yup.string().required(`${city.requiredErrorMsg}`),
    [knew.name]: Yup.string().required(`${knew.requiredErrorMsg}`),
  }),
];
