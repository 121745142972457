export const labs = [
  {
    id: 2,
    name: 'LMP',
    palette: {
      primary: '#1E305A',
      secondary: '#1f5582',
    },
    about: {
      bannerItems: [
        {
          name: 'Usinagem',
          description:
            'Pesquisa em usinagem com ferramentas de geometria definida e não definida, materiais autolubrificantes e ferramentas texturizadas',
          image:
            'https://www.laddwelding.com/wp-content/uploads/2017/04/machining-1.jpg',
        },
        {
          name: 'Tecnologia laser',
          description:
            'Manufatura aditiva, texturização de ferramentas, soldagem, tratamento térmico e gravação de materiais a laser',
          image:
            'https://trello-attachments.s3.amazonaws.com/5df3a9d74458ba69cb17da40/5f89972e4ef8ef5af85fdcb2/e15bae741339f3d4ede74e1155cf31b9/image.jpg',
        },
      ],
      fullName: 'Laboratório de Mecânica de Precisão',
      email: 'lmp@contato.ufsc.br',
      phone: '+55 (48) 3721-7229',
      address:
        'R. Eng. Agronômico Andrei Cristian Ferreira, s/n Trindade, Florianópolis, SC CEP 88040-900',
      details: `O LMP é um dos laboratórios do Departamento de Engenharia Mecânica da Universidade Federal de Santa Catarina de maior projeção nacional e internacional. Sua criação, em 1990, se deu devido à necessidade de modernização da estrutura do curso de Engenharia Mecânica, aproveitando-se o conhecimento e a experiência adquiridos no antigo Laboratório de Máquinas-Ferramentas - USIMAQ.
      No Laboratório de Mecânica de Precisão são desenvolvidas pesquisas tecnológicas aplicadas na área de usinagem convencional com ferramentas de geometria definida e não definida, voltadas também para a fabricação de precisão e ultraprecisão. A partir de 2005 o laboratório deu início a um grupo de pesquisa em aplicações industriais da tecnologia LASER, que juntamente com parcerias internacionais, desenvolve e disponibiliza os últimos avanços desta tecnologia. Dentre as áreas estudadas, destacam-se: manufatura aditiva, tratamento superficial e microssoldagem a LASER.`,
    },
  },
  {
    id: 3,
    name: 'CERMAT',
    palette: {
      primary: '#dd8a43',
      secondary: '#1f5582',
    },
    about: {
      bannerItems: [
        {
          name: 'KIC Measurement by ICL',
          description:
            'Indentation Crack Length (ICL) is method often used to determine the toughness of fragile materials. The method consists of measuring the length of the cracks produced at the edges of the Vickers indentation as a consequence of the applied load.',
          image:
            'https://drive.google.com/uc?export=view&id=1biFdmMwRckKN6m-TQz_-Fq9HYzTlwUIF',
        },
        {
          name: 'Nanostructured biocompatible ceramics and glass-ceramics',
          description:
            'Description of the evolution of bioactive ceramics used as scaffolds, implant coatings, and drug-loading system for bone healing. Book: Nanostructured biomaterials for cranio-maxillofacial and oral applications, First Edition, Chapter 6.',
          image:
            'https://trello-attachments.s3.amazonaws.com/5f7d13ba41ecf76b44d3d2d2/599x255/ae024d6314f3bd2fb8c272bed50abfc6/image.png',
        },
        {
          name: 'Queimadores Porosos',
          description: `Desenvolvimento de cerâmicas e aplicações da tecnologia de combustão em meios porosos.
            O projeto, financiado pela Petrobrás, tem como objetivo obter sistemas de combustão mais eficientes,
            compactos e com vantagens no controle de processos `,
          image:
            'https://trello-attachments.s3.amazonaws.com/5df3a9d74458ba69cb17da40/5fabe21e2b55d42054b31ebe/81179cf896b3572de4a8e3e02274b838/microscopia-fredel.png',
        },
      ],
      fullName: 'Núcleo de Pesquisas em Materiais Cerâmicos & Compósitos',
      email: 'cermat@contato.ufsc.br',
      phone: '+55 (48) 3721-7223',
      address:
        'R. Eng. Agronômico Andrei Cristian Ferreira, s/n Trindade, Florianópolis, SC CEP 88040-900',
      details:
        'A origem da Divisão de Processamento de Materiais Cerâmicos disposta no conjunto de atividades do CERMAT, Núcleo de Pesquisa em Materiais Cerâmicos e Compósitos do Departamento de Eng. Mecânica da Universidade Federal de Santa Catarina remonta sua origem em 1991, quando se iniciou, no âmbito da Pós-Graduação em Engenharia Mecânica, um programa de formação de recursos humanos, mestres e doutores, na área de cerâmica, voltado à pesquisa científica e tecnológica.',
    },
  },
];

export const services = [
  {
    id: 1,
    labid: 2,
    title: 'Gravação a laser de materiais metálicos e não metálicos',
    description:
      'A ablação é um processo de retirada de material por pulso de laser.',
    imageurl:
      'https://trello-attachments.s3.amazonaws.com/5df3a9d74458ba69cb17da40/5f89972e4ef8ef5af85fdcb2/eee7d85e5495d0e6f23a719ff9984f14/datamark-fiber-laser-marking-systems-2_(1).jpg',
  },
  {
    id: 1,
    labid: 2,
    title: 'Manufatura aditiva e revestimento de materiais metálicos',
    description:
      'Manufatura aditiva de metais pelo método de deposição direta de energia a laser',
    imageurl:
      'https://trello-attachments.s3.amazonaws.com/5df3a9d74458ba69cb17da40/5f89972e4ef8ef5af85fdcb2/9ecf249c00e8ee9510ad569794359826/ded.jpg',
  },
  {
    id: 1,
    labid: 2,
    title: 'Soldagem de alta penetração a laser',
    description:
      'Soldagem com laser de fibra de alta potência, com penetração de até 15mm em um passe.',
    imageurl:
      'https://upload.wikimedia.org/wikipedia/commons/c/c9/High-power_laser_welding.jpg',
  },
  {
    id: 1,
    labid: 2,
    title: 'Microssoldagem a laser',
    description:
      'Soldagem a laser de fibra para chapas finas de até 2mm de espessura.',
    imageurl:
      'https://amadaweldtech.com/wp-content/uploads/2013/01/LW5AG-green-laser-welding-copper-1200x590-1.jpg',
  },
  {
    id: 1,
    labid: 2,
    title: 'Fabricação de componentes metálicos e não metálicos por usinagem',
    description:
      'Fabricação de componentes metálicos e não metálicos conforme projeto do solicitante.',
    imageurl:
      'https://www.diferro.com.br/igc/uploadAr/FileProcessingScripts/PHP/UploadedFiles/usinagem-facil-din-11smn30-e-37-diferro1550685711-611191.png',
  },
  {
    id: 1,
    labid: 2,
    title: 'Corte de materiais frágeis com fio diamantado',
    description:
      'Corte de seções transversais de materiais diversos utilizando fio diamantado.',
    imageurl:
      'https://www.diamondwiretec.com/files/userImages/lohn-trennschnitte/2Sensor.JPG',
  },
  {
    id: 1,
    labid: 2,
    title: 'Tratamento térmico de superfícies por laser',
    description:
      'O laser de alta potência pode ser utilizado para tratar superfícies e endurecer materiais.',
    imageurl:
      'https://www.nutech.de/images/galerie/laseroberflaechenbehandlung/laserhaerten/laserhaerten%20ring.jpg',
  },
];
