import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
// import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';
// import ImportExportIcon from '@material-ui/icons/ImportExport';

// import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
// import EditIcon from '@material-ui/icons/Edit';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
import { grey } from '@material-ui/core/colors';
import Copyright from '../../components/Copyright';
import Title from './components/Title';
import OutlinedCard from './components/OutlinedCard';
import { setLabState } from '~/store/modules/lab/actions';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: 'auto',
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  paper: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function createData(id, title, mediaImage, route) {
  return { id, title, mediaImage, route };
}

const labs = [
  createData(
    2,
    'LMP',
    'https://trello-attachments.s3.amazonaws.com/5df3a9d74458ba69cb17da40/5f5d355ffd004c70c316531d/f23d6f94b29efb58dc703c4610ebff72/lmp.svg',
    'lmp'
  ),
  createData(
    3,
    'CERMAT',
    'https://trello-attachments.s3.amazonaws.com/5f5d355ffd004c70c316531d/453x341/a8f5fed3ccef06e44c7826304b501c50/image.png',
    'cermat'
  ),
];

export default function Dashboard() {
  const classes = useStyles();
  const profile = useSelector(state => state.user.profile);

  /** Define page name to change appbar title */
  const name = 'PEERLAB';
  const palette = {
    primary: grey[50],
    secondary: grey[50],
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLabState({ name, palette }));
  }, [dispatch, palette]);

  return (
    <main className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {profile && <Title>Meus laboratórios</Title>}
              <Grid container spacing={2}>
                {labs.map(lab => {
                  if (profile && lab.id === profile.lab_id) {
                    return (
                      <Grid item key={lab.id} xs={6} sm={4} md={3} lg={2}>
                        <OutlinedCard
                          title={lab.title}
                          mediaImage={lab.mediaImage}
                          route={lab.route}
                        />
                      </Grid>
                    );
                  }

                  return null;
                })}
              </Grid>
            </Grid>
            {/* Chart */}
            <Grid item xs={12}>
              <Title>Laboratórios</Title>
              <Grid container spacing={2}>
                {labs.map(lab => {
                  if (profile && lab.id !== profile.lab_id) {
                    return (
                      <Grid item key={lab.id} xs={6} sm={4} md={3} lg={2}>
                        <OutlinedCard
                          title={lab.title}
                          mediaImage={lab.mediaImage}
                          route={lab.route}
                        />
                      </Grid>
                    );
                  }
                  if (!profile) {
                    return (
                      <Grid item key={lab.id} xs={6} sm={4} md={3} lg={2}>
                        <OutlinedCard
                          title={lab.title}
                          mediaImage={lab.mediaImage}
                          route={lab.route}
                        />
                      </Grid>
                    );
                  }
                  return null;
                })}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Copyright />
    </main>
  );
}
